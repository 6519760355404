import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ant-row" }
const _hoisted_2 = { class: "ant-col ant-col-xs-24 ant-col-xl-24" }
const _hoisted_3 = { class: "ant-row" }
const _hoisted_4 = { class: "ant-col ant-col-xs-12 ant-col-xl-4" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = { class: "ant-col ant-col-xs-12 ant-col-xl-5" }
const _hoisted_7 = { class: "ant-col ant-col-xs-24 ant-col-xl-15" }
const _hoisted_8 = { class: "ant-row" }
const _hoisted_9 = { class: "ant-col ant-col-xs-24 ant-col-xl-12" }
const _hoisted_10 = { class: "ant-col ant-col-xs-24 ant-col-xl-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_campo_numerico = _resolveComponent("campo-numerico")!
  const _component_selecionar_tipo_documento_financeiro = _resolveComponent("selecionar-tipo-documento-financeiro")!
  const _component_selecionar_conta = _resolveComponent("selecionar-conta")!
  const _component_selecionar_categoria_plano_conta = _resolveComponent("selecionar-categoria-plano-conta")!
  const _component_card = _resolveComponent("card")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createBlock(_component_a_form, { layout: "vertical" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_card, {
            titulo: "DADOS DO CRÉDITO",
            class: "ss-margin-campos"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_a_form_item, {
                    label: "N.Identificação",
                    class: "ss-margin-campos"
                  }, {
                    default: _withCtx(() => [
                      _withDirectives(_createElementVNode("input", {
                        type: "text",
                        class: "ant-input",
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedDetalheCredito.numeroTitulo) = $event)),
                        disabled: !_ctx.props.editavel
                      }, null, 8, _hoisted_5), [
                        [_vModelText, _ctx.computedDetalheCredito.numeroTitulo]
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_a_form_item, {
                    label: "Valor",
                    class: "ss-margin-campos"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_campo_numerico, {
                        monetario: true,
                        quantidadeCasasDecimais: 2,
                        valor: _ctx.computedDetalheCredito.valor,
                        disabled: true
                      }, null, 8, ["valor"])
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_a_form_item, {
                    label: "Tipo de Documento",
                    class: "ss-margin-campos"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_selecionar_tipo_documento_financeiro, {
                        empresas: [_ctx.props.empresa],
                        meioPagamento: 5,
                        codigoSelecionado: _ctx.computedDetalheCredito.codigoTipoDocumentoFinanceiro,
                        "onUpdate:codigoSelecionado": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.computedDetalheCredito.codigoTipoDocumentoFinanceiro) = $event)),
                        descricaoSelecionado: _ctx.computedDetalheCredito.descricaoTipoDocumentoFinanceiro,
                        "onUpdate:descricaoSelecionado": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.computedDetalheCredito.descricaoTipoDocumentoFinanceiro) = $event)),
                        disabled: !_ctx.props.editavel
                      }, null, 8, ["empresas", "codigoSelecionado", "descricaoSelecionado", "disabled"])
                    ]),
                    _: 1
                  })
                ])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createVNode(_component_selecionar_conta, {
                    empresas: [_ctx.props.empresa],
                    codigoSelecionado: _ctx.computedDetalheCredito.codigoConta,
                    "onUpdate:codigoSelecionado": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.computedDetalheCredito.codigoConta) = $event)),
                    descricaoSelecionado: _ctx.computedDetalheCredito.descricaoConta,
                    "onUpdate:descricaoSelecionado": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.computedDetalheCredito.descricaoConta) = $event)),
                    disabled: !_ctx.props.editavel
                  }, null, 8, ["empresas", "codigoSelecionado", "descricaoSelecionado", "disabled"])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createVNode(_component_a_form_item, {
                    label: "Categoria do Plano de Contas",
                    class: "ss-margin-campos"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_selecionar_categoria_plano_conta, {
                        empresas: [_ctx.props.empresa],
                        tipoPlanoConta: 1,
                        codigoSelecionado: _ctx.computedDetalheCredito.codigoPlanoContaCategoria,
                        "onUpdate:codigoSelecionado": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.computedDetalheCredito.codigoPlanoContaCategoria) = $event)),
                        descricaoSelecionado: _ctx.computedDetalheCredito.nomePlanoContaCategoria,
                        "onUpdate:descricaoSelecionado": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.computedDetalheCredito.nomePlanoContaCategoria) = $event)),
                        disabled: !_ctx.props.editavel
                      }, null, 8, ["empresas", "codigoSelecionado", "descricaoSelecionado", "disabled"])
                    ]),
                    _: 1
                  })
                ])
              ])
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }))
}