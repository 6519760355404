
import { defineComponent, reactive, watch } from 'vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import ServicoControleAutorizacoes from '@/servicos/MeuSistema/ServicoControleAutorizacoes';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import { EStatusRequisicaoAutorizacao } from '@/models/Enumeradores/MeuSistema/EStatusRequisicaoAutorizacao';
import { EStatusRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IRequisicaoAutorizacao } from '@/models/Entidades/MeuSistema/IRequisicaoAutorizacao';

export default defineComponent({
  name: 'RequisitarAutorizacaoModal',
  props: {
    visivel: {
      type: Boolean,
    },
    identificadorPermissao: {
      type: String,
      required: true,
    },
    descricao: {
      type: String,
      required: true,
    },
  },
  components: {
  },
  emits: ['update:visivel', 'autorizada', 'negada'],
  setup(props, { emit }) {
    const { telaBase, apresentarMensagemErro } = useTelaBase();
    const { modalBase } = useModalBase(props, emit);
    const servicoControleAutorizacoes = new ServicoControleAutorizacoes();
    telaBase.identificadorRecurso = 'CONTROLE_AUTORIZACOES';
    telaBase.identificadorPermissao = 'AUT_CONTROLE_AUTORIZACOES';

    const state = reactive({
      requisicao: {} as IRequisicaoAutorizacao,
      intervalo: 0,
      codigo: 0,
      descricao: '',
      resposta: '',
      requisitando: false,
      expirada: false,
      negada: false,
    });

    async function obterResposta() {
      if (state.requisitando && state.codigo > 0) {
        state.requisicao = await servicoControleAutorizacoes.obterRequisicao(state.codigo);
        if (state.requisicao.status === EStatusRequisicaoAutorizacao.Autorizada) {
          emit('autorizada');
          modalBase.computedVisivel = false;
        } else if (state.requisicao.status === EStatusRequisicaoAutorizacao.Expirada) {
          state.expirada = true;
          state.requisitando = false;
        } else if (state.requisicao.status === EStatusRequisicaoAutorizacao.Negada) {
          state.resposta = state.requisicao.resposta;
          state.negada = true;
          state.requisitando = false;
        }
      }
    }

    function cancelar() {
      emit('negada');
      modalBase.computedVisivel = false;
    }

    async function requisitar() {
      state.requisitando = true;
      state.expirada = false;
      state.negada = false;
      state.resposta = '';
      const retorno = await servicoControleAutorizacoes.criarRequisicao(props.identificadorPermissao, state.descricao);
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        state.codigo = retorno.codigoRegistro;
      } else {
        apresentarMensagemErro(retorno.mensagem);
        state.requisitando = false;
      }
    }

    function limparCampos() {
      state.requisicao = {} as IRequisicaoAutorizacao;
      state.codigo = 0;
      state.descricao = '';
      state.resposta = '';
      state.requisitando = false;
      state.expirada = false;
      state.negada = false;
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      clearInterval(state.intervalo);
      if (modalBase.computedVisivel) {
        state.intervalo = setInterval(() => { obterResposta(); }, 10000);
        limparCampos();
        state.descricao = props.descricao;
      }
      telaBase.carregando = false;
    });

    return {
      props,
      state,
      telaBase,
      modalBase,
      UtilitarioData,
      EStatusRequisicaoAutorizacao,
      servicoControleAutorizacoes,
      cancelar,
      requisitar,
    };
  },
});
