
import { defineComponent, reactive, computed } from 'vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import Card from '@/core/components/Tela/Card.vue';
import SelecionarData from '@/core/components/Tela/SelecionarData.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import SelecionarCategoriaPlanoConta from '@/components/Cadastros/PlanosContas/SelecionarCategoriaPlanoConta.vue';
import { INotaFiscalDetalhePagamento } from '@/models/Entidades/Fiscal/INotaFiscal';
import NotaFiscalDetalhePagamentoCentroCusto from '@/components/Fiscal/NotasFiscais/NotaFiscalDetalhePagamentoCentroCusto.vue';
import NotaFiscalDetalhePagamentoAnexo from '@/components/Fiscal/NotasFiscais/NotaFiscalDetalhePagamentoAnexo.vue';
import ServicoNotaFiscal from '@/servicos/Fiscal/ServicoNotaFiscal';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import SelecionarConta from '@/components/Cadastros/Financeiro/SelecionarConta.vue';

export default defineComponent({
  name: 'VendaDetalhePagamentoOutros',
  props: {
    editavel: {
      type: Boolean,
      required: true,
    },
    empresa: {
      type: Number,
      required: true,
    },
    detalhePagamento: {
      type: Object as () => INotaFiscalDetalhePagamento,
      required: true,
    },
  },
  components: {
    Card,
    SelecionarData,
    CampoNumerico,
    SelecionarConta,
    SelecionarCategoriaPlanoConta,
    NotaFiscalDetalhePagamentoCentroCusto,
    NotaFiscalDetalhePagamentoAnexo,
  },
  emits: ['update:detalhePagamento', 'alterarTipoDocumento', 'atualizarValorTotal'],
  setup(props, { emit }) {
    const servicoNotaFiscal = new ServicoNotaFiscal();
    servicoNotaFiscal.requisicaoSistema();
    const { telaBase } = useTelaBase();

    const state = reactive({});

    const computedDetalhePagamento = computed({
      get: () => props.detalhePagamento,
      set: (val: INotaFiscalDetalhePagamento) => {
        emit('update:detalhePagamento', val);
      },
    });

    function alterarTipoDocumento() {
      emit('alterarTipoDocumento');
    }

    function atualizarValorTotal() {
      computedDetalhePagamento.value.valorTotal = computedDetalhePagamento.value.valorPagamento + computedDetalhePagamento.value.acrescimos + computedDetalhePagamento.value.outrasTaxas;
    }

    return {
      props,
      state,
      telaBase,
      UtilitarioGeral,
      computedDetalhePagamento,
      alterarTipoDocumento,
      atualizarValorTotal,
    };
  },
});
