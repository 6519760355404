
import {
  computed, defineComponent, reactive, ref, watch,
} from 'vue';

import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import ServicoTabelaPreco from '@/servicos/Cadastros/Precificacao/ServicoTabelaPreco';
import { IDTOTabelaPrecoItemProduto } from '@/models/DTO/Cadastros/Precificacao/IDTOTabelaPrecoItemProduto';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';

export interface IDTOTabelaPrecoSelecao {
    codigo:number;
    descricao: string;
    itens: IDTOTabelaPrecoItemProduto[];
  }
export default defineComponent({
  name: 'SelecionarPrecoProdutoMovimento',
  props: {
    codigoProdutoDefinicao: {
      type: Number,
      required: true,
    },
    codigoItemSelecionado: {
      type: Number,
      default: 0,
    },
    casasDecimais: {
      type: Number,
      default: 2,
    },
    dadosItemTabelaPrecoSelecionado: {
      type: Object as () => IDTOTabelaPrecoItemProduto,
      default: {} as IDTOTabelaPrecoItemProduto,
    },
    codigosEspecificos: {
      type: Array as () => number[],
      default: [] as number[],
    },
    empresas: {
      type: Array as () => number[],
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Selecione a Item da Tabela de Preços',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    classCss: {
      type: String,
      default: '',
    },
    processando: {
      type: Boolean,
      default: false,
    },
    apresentarPrecos: {
      type: Boolean,
      default: false,
    },
    identificacaoElemento: {
      type: String,
      default: '',
    },
    popOverHover: {
      type: Boolean,
      default: false,
    },
    inputFocus: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:codigoItemSelecionado', 'update:apresentarPrecos', 'update:popOverHover', 'update:inputFocus', 'update:casasDecimais', 'update:dadosItemTabelaPrecoSelecionado', 'update:processando', 'change', 'blur', 'definirPrecoSelecionado'],
  setup(props, { emit }) {
    const refSelecionarTabelaPreco = ref<HTMLElement | null>(null);
    const servicoTabelaPreco = new ServicoTabelaPreco();

    const state = reactive({
      itens: [] as IDTOTabelaPrecoItemProduto[],
      tabelasPrecos: [] as IDTOTabelaPrecoSelecao[],
      buscandoDados: false,
    });

    const computedProcessando = computed({
      get: () => props.processando,
      set: (val: boolean) => {
        emit('update:processando', val);
      },
    });

    const computedApresentarPrecos = computed({
      get: () => props.apresentarPrecos,
      set: (val: boolean) => {
        emit('update:apresentarPrecos', val);
      },
    });

    const computedPopOverHover = computed({
      get: () => props.popOverHover,
      set: (val: boolean) => {
        emit('update:popOverHover', val);
      },
    });

    const computedInputFocus = computed({
      get: () => props.inputFocus,
      set: (val: boolean) => {
        emit('update:inputFocus', val);
      },
    });

    const computedCasasDecimais = computed({
      get: () => props.casasDecimais,
      set: (val: number) => {
        emit('update:casasDecimais', val);
      },
    });

    function obterCodigoItem():any {
      return (props.codigoItemSelecionado === 0 ? undefined : props.codigoItemSelecionado);
    }

    function atualizarDadosItemSelecionado(codigoItem: number) {
      if (codigoItem > 0) {
        const itemSelecionado = state.itens.find((c) => c.codigo === codigoItem);
        if (itemSelecionado !== undefined) {
          computedCasasDecimais.value = itemSelecionado.casasDecimaisTipoPreco;
          emit('update:dadosItemTabelaPrecoSelecionado', itemSelecionado);
        } else {
          const itemTabelaPreco:IDTOTabelaPrecoItemProduto = {} as IDTOTabelaPrecoItemProduto;
          itemTabelaPreco.codigo = 0;
          itemTabelaPreco.codigoTabelaPreco = 0;
          itemTabelaPreco.descricaoTabelaPreco = '';
          itemTabelaPreco.codigoTipoCusto = 0;
          itemTabelaPreco.descricaoTipoCusto = '';
          itemTabelaPreco.codigoTipoPreco = 0;
          itemTabelaPreco.descricaoTipoPreco = '';
          itemTabelaPreco.codigoProdutoDefinicao = props.codigoProdutoDefinicao;
          itemTabelaPreco.casasDecimaisTipoPreco = props.casasDecimais;
          itemTabelaPreco.custo = 0;
          itemTabelaPreco.valor = 0;
          itemTabelaPreco.markup = 0;
          itemTabelaPreco.descontoMaximo = 0;
          itemTabelaPreco.dataAlteracao = '';
          emit('update:dadosItemTabelaPrecoSelecionado', itemTabelaPreco);
        }
      }
    }
    const computedCodigoItemSelecionado = computed({
      get: () => obterCodigoItem(),
      set: (codigoSelecionado: number) => {
        const itemTabelaPreco = state.itens.find((c) => c.codigo === codigoSelecionado);
        if (itemTabelaPreco !== undefined && itemTabelaPreco != null) {
          computedCasasDecimais.value = itemTabelaPreco.casasDecimaisTipoPreco;
        }

        emit('update:codigoItemSelecionado', codigoSelecionado);
        atualizarDadosItemSelecionado(codigoSelecionado);
      },
    });

    async function obterDadosItens() {
      state.buscandoDados = true;
      computedProcessando.value = true;
      state.itens = [];
      state.tabelasPrecos = [];
      const itensTabelaPreco = await servicoTabelaPreco.obterTabelasProdutosDefinicoes(props.empresas, [props.codigoProdutoDefinicao], [], true);
      if (UtilitarioGeral.validaLista(itensTabelaPreco)) {
        itensTabelaPreco.forEach((itemTabela) => {
          const indexTabela = state.tabelasPrecos.findIndex((c) => c.codigo === itemTabela.codigoTabelaPreco);
          if (indexTabela >= 0) {
            state.tabelasPrecos[indexTabela].itens.push(itemTabela);
          } else {
            const tabelaPreco:IDTOTabelaPrecoSelecao = { codigo: itemTabela.codigoTabelaPreco, descricao: itemTabela.descricaoTabelaPreco } as IDTOTabelaPrecoSelecao;
            tabelaPreco.itens = [];
            tabelaPreco.itens.push(itemTabela);
            state.tabelasPrecos.push(tabelaPreco);
          }
        });
      }

      state.itens = itensTabelaPreco;
      state.buscandoDados = false;
      computedProcessando.value = false;
    }

    function change() {
      emit('change', props.codigoItemSelecionado);
    }

    function focus() {
      if (refSelecionarTabelaPreco.value) {
        refSelecionarTabelaPreco.value.focus();
      }
    }
    function blur() {
      computedInputFocus.value = false;
      emit('blur');
    }

    function obterDescricaoItem(item: IDTOTabelaPrecoItemProduto) {
      return `${item.descricaoTabelaPreco} - ${item.descricaoTipoPreco}`;
    }

    function definePrecoItemTabelaSelecionado(itemTabela:IDTOTabelaPrecoItemProduto) {
      computedCodigoItemSelecionado.value = itemTabela.codigo;
      computedCasasDecimais.value = itemTabela.casasDecimaisTipoPreco;
      computedInputFocus.value = false;
      emit('definirPrecoSelecionado', itemTabela.valor);
    }

    watch(async () => props.codigoProdutoDefinicao, async () => {
      if (!UtilitarioGeral.validaCodigo(props.codigoItemSelecionado) && props.codigoProdutoDefinicao > 0 && !UtilitarioGeral.validaLista(state.itens)) {
        await obterDadosItens();
        if (UtilitarioGeral.validaLista(state.itens) && props.codigoItemSelecionado === 0) {
          emit('update:codigoItemSelecionado', state.itens[0].codigo);
          emit('update:dadosItemTabelaPrecoSelecionado', state.itens[0]);
          definePrecoItemTabelaSelecionado(state.itens[0]);
        }
      } else {
        atualizarDadosItemSelecionado(props.codigoItemSelecionado);
      }
    }, { immediate: true });

    return {
      props,
      state,
      refSelecionarTabelaPreco,
      computedCodigoItemSelecionado,
      change,
      focus,
      blur,
      obterDescricaoItem,
      definePrecoItemTabelaSelecionado,
      UtilitarioMascara,
      computedApresentarPrecos,
      computedPopOverHover,
    };
  },
});
