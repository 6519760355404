import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select = _resolveComponent("a-select")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.props.varios)
      ? (_openBlock(), _createBlock(_component_a_select, {
          key: 0,
          class: "ss-tamanho-completo",
          value: _ctx.computedCodigosSelecionados,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedCodigosSelecionados) = $event)),
          mode: "multiple",
          allowClear: true,
          placeholder: _ctx.props.placeholder,
          "show-search": "",
          optionFilterProp: "label",
          options: _ctx.state.listaModalidadesBaseCalculoIcms,
          disabled: _ctx.props.disabled,
          onChange: _ctx.change
        }, null, 8, ["value", "placeholder", "options", "disabled", "onChange"]))
      : (_openBlock(), _createBlock(_component_a_select, {
          key: 1,
          class: "ss-tamanho-completo",
          value: _ctx.computedCodigoSelecionado,
          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.computedCodigoSelecionado) = $event)),
          placeholder: _ctx.props.placeholder,
          "show-search": "",
          optionFilterProp: "label",
          options: _ctx.state.listaModalidadesBaseCalculoIcms,
          disabled: _ctx.props.disabled,
          onChange: _ctx.change
        }, null, 8, ["value", "placeholder", "options", "disabled", "onChange"]))
  ]))
}