import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ant-row" }
const _hoisted_2 = { class: "ant-col ant-col-xs-12 ant-col-xl-6" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = { class: "ant-col ant-col-xs-12 ant-col-xl-5" }
const _hoisted_5 = { class: "ant-row" }
const _hoisted_6 = { class: "ant-col ant-col-xs-24 ant-col-xl-6" }
const _hoisted_7 = ["disabled"]
const _hoisted_8 = { class: "ant-col ant-col-xs-24 ant-col-xl-13" }
const _hoisted_9 = ["disabled"]
const _hoisted_10 = { class: "ant-col ant-col-xs-24 ant-col-xl-5" }
const _hoisted_11 = ["disabled"]
const _hoisted_12 = { class: "ant-row" }
const _hoisted_13 = { class: "ant-col ant-col-xs-12 ant-col-xl-5" }
const _hoisted_14 = { class: "ant-col ant-col-xs-12 ant-col-xl-5" }
const _hoisted_15 = { class: "ant-col ant-col-xs-12 ant-col-xl-5" }
const _hoisted_16 = { class: "ant-col ant-col-xs-12 ant-col-xl-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_campo_numerico = _resolveComponent("campo-numerico")!
  const _component_card = _resolveComponent("card")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, { layout: "vertical" }, {
          default: _withCtx(() => [
            _createVNode(_component_card, { titulo: "MEDICAMENTO" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_a_form_item, {
                      label: "Código da ANVISA",
                      class: "ss-margin-campos"
                    }, {
                      default: _withCtx(() => [
                        _withDirectives(_createElementVNode("input", {
                          type: "text",
                          class: "ant-input",
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedItem.medicamento.codigoAnvisa) = $event)),
                          disabled: !_ctx.props.editavel
                        }, null, 8, _hoisted_3), [
                          [_vModelText, _ctx.computedItem.medicamento.codigoAnvisa]
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_a_form_item, {
                      label: "Preço max. ao consumidor",
                      class: "ss-margin-campos"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_campo_numerico, {
                          monetario: true,
                          quantidadeCasasDecimais: 2,
                          valor: _ctx.computedItem.medicamento.precoMaximoConsumidor,
                          "onUpdate:valor": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.computedItem.medicamento.precoMaximoConsumidor) = $event)),
                          disabled: !_ctx.props.editavel
                        }, null, 8, ["valor", "disabled"])
                      ]),
                      _: 1
                    })
                  ])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_card, { titulo: "COMBUSTÍVEL" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_a_form_item, {
                      label: "Código da ANP",
                      class: "ss-margin-campos"
                    }, {
                      default: _withCtx(() => [
                        _withDirectives(_createElementVNode("input", {
                          type: "text",
                          class: "ant-input",
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.computedItem.combustivel.codigoAnp) = $event)),
                          disabled: !_ctx.props.editavel
                        }, null, 8, _hoisted_7), [
                          [_vModelText, _ctx.computedItem.combustivel.codigoAnp]
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_a_form_item, {
                      label: "Descrição da ANP",
                      class: "ss-margin-campos"
                    }, {
                      default: _withCtx(() => [
                        _withDirectives(_createElementVNode("input", {
                          type: "text",
                          class: "ant-input",
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.computedItem.combustivel.descricaoAnp) = $event)),
                          disabled: !_ctx.props.editavel
                        }, null, 8, _hoisted_9), [
                          [_vModelText, _ctx.computedItem.combustivel.descricaoAnp]
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", _hoisted_10, [
                    _createVNode(_component_a_form_item, {
                      label: "Código de autorização - CODIF",
                      class: "ss-margin-campos"
                    }, {
                      default: _withCtx(() => [
                        _withDirectives(_createElementVNode("input", {
                          type: "text",
                          class: "ant-input",
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.computedItem.combustivel.autorizacaoCodif) = $event)),
                          disabled: !_ctx.props.editavel
                        }, null, 8, _hoisted_11), [
                          [_vModelText, _ctx.computedItem.combustivel.autorizacaoCodif]
                        ])
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("div", _hoisted_13, [
                    _createVNode(_component_a_form_item, {
                      label: "% da GLP",
                      class: "ss-margin-campos"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_campo_numerico, {
                          percentual: true,
                          quantidadeCasasDecimais: 2,
                          modelValue: _ctx.computedItem.combustivel.percentualGlp,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.computedItem.combustivel.percentualGlp) = $event)),
                          disabled: !_ctx.props.editavel
                        }, null, 8, ["modelValue", "disabled"])
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", _hoisted_14, [
                    _createVNode(_component_a_form_item, {
                      label: "% de Gás Natural Nacional",
                      class: "ss-margin-campos"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_campo_numerico, {
                          percentual: true,
                          quantidadeCasasDecimais: 2,
                          modelValue: _ctx.computedItem.combustivel.percentualGnn,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.computedItem.combustivel.percentualGnn) = $event)),
                          disabled: !_ctx.props.editavel
                        }, null, 8, ["modelValue", "disabled"])
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", _hoisted_15, [
                    _createVNode(_component_a_form_item, {
                      label: "% de Gás Importado",
                      class: "ss-margin-campos"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_campo_numerico, {
                          percentual: true,
                          quantidadeCasasDecimais: 2,
                          modelValue: _ctx.computedItem.combustivel.percentualGn,
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.computedItem.combustivel.percentualGn) = $event)),
                          disabled: !_ctx.props.editavel
                        }, null, 8, ["modelValue", "disabled"])
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", _hoisted_16, [
                    _createVNode(_component_a_form_item, {
                      label: "Valor de Partida",
                      class: "ss-margin-campos"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_campo_numerico, {
                          monetario: true,
                          quantidadeCasasDecimais: 2,
                          modelValue: _ctx.computedItem.combustivel.valorPartida,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.computedItem.combustivel.valorPartida) = $event)),
                          disabled: !_ctx.props.editavel
                        }, null, 8, ["modelValue", "disabled"])
                      ]),
                      _: 1
                    })
                  ])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}