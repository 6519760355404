import ApiERP from '@/core/conectores/ApiERP';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { IListaPaginada } from '@/core/models/Consulta/IListaPaginada';
import { IPropriedadeConsulta } from '@/core/models/Consulta/PropriedadeConsulta';
import { IParametrosConsulta } from '@/core/models/Consulta/IParametrosConsulta';
import { IServicoBase } from '@/core/models/IServicoBase';
import { IItemConsultaRapida } from '@/core/models/Consulta/IItemConsultaRapida';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import { IRetornoRelatorio } from '@/core/models/IRetornoRelatorio';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import { ICfop } from '@/models/Entidades/Cadastros/Tributacoes/ICfop';
import ServicoSistema from '@/servicos/MeuSistema/ServicoSistema';
import storeSistema from '@/store/storeSistema';

/**
 * Serviço de CFOPs
 * Fornece todas regras de negócios e lógicas relacionado a CFOPs.
 */

class ServicoCfop implements IServicoBase<ICfop> {
    endPoint = 'cfops';

    apiERP = new ApiERP();

    public requisicaoSistema():void {
      this.apiERP = new ApiERP(true);
    }

    public async obterPropriedadesConsulta(): Promise<IPropriedadeConsulta[]> {
      const listaPropriedades = await this.apiERP.obterPropriedadesConsulta(this.endPoint);
      return listaPropriedades;
    }

    public async buscaAvancada(parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[]): Promise<IListaPaginada> {
      return this.apiERP.buscaAvancada(this.endPoint, parametrosConsulta, filtros);
    }

    public async relatorioPadrao(tipoArquivo: ETipoArquivo, parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[], codigosSelecionados?: Array<number>): Promise<IRetornoRelatorio> {
      return this.apiERP.relatorioPadrao(this.endPoint, tipoArquivo, parametrosConsulta, filtros, codigosSelecionados);
    }

    public obterIdentificacaoRegistroGradeCRUD(objeto: ICfop): string {
      return `${objeto.codigoCfop} - ${objeto.descricao}`;
    }

    public async consultaRapida(parametrosConsultaRapida: IParametrosConsultaRapida, filtrarApenasCfopsEntradas?:boolean, filtrarApenasCfopsSaidas?:boolean, tipoOperacao?: number): Promise<IItemConsultaRapida[]> {
      let parametrosAdicionais = this.apiERP.obterParametrosAdicionaisConsultaRapida(parametrosConsultaRapida);
      if (filtrarApenasCfopsEntradas !== undefined) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `filtrarApenasCfopsEntradas=${filtrarApenasCfopsEntradas}`;
      }
      if (filtrarApenasCfopsSaidas !== undefined) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `filtrarApenasCfopsSaidas=${filtrarApenasCfopsSaidas}`;
      }

      if (tipoOperacao !== undefined) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `tipoOperacao=${tipoOperacao}`;
      }
      const result: any = await this.apiERP.get(`${this.endPoint}/consulta-rapida${parametrosAdicionais}`);
      return result.data;
    }

    public async incluir(objeto: ICfop): Promise<IRetornoRequisicao> {
      const dadosCfop:ICfop = objeto;
      dadosCfop.dataCadastro = await new ServicoSistema().obterDataAtual();
      const result: any = await this.apiERP.post(`${this.endPoint}/empresa/${storeSistema.getters.codigoEmpresaOperacao()}`, objeto);
      return result.data;
    }

    public async alterar(objeto: ICfop): Promise<IRetornoRequisicao> {
      const dadosCfop:ICfop = objeto;
      dadosCfop.dataAlteracao = await new ServicoSistema().obterDataAtual();
      const result: any = await this.apiERP.put(`${this.endPoint}/${objeto.codigo}/empresa/${storeSistema.getters.codigoEmpresaOperacao()}`, objeto);
      return result.data;
    }

    public async obter(codigo: number, empresa: number): Promise<ICfop> {
      const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/empresa/${empresa}`);
      return result.data;
    }

    public async obterIdentificador(codigoCfop: string): Promise<number> {
      const result: any = await this.apiERP.get(`${this.endPoint}/codigo-cfop/${codigoCfop}/identificador`);
      return result.data;
    }

    public async obterPeloCodigoCfop(codigoCfop: number): Promise<ICfop> {
      const result: any = await this.apiERP.get(`${this.endPoint}/codigo-cfop/${codigoCfop}`);
      return result.data;
    }

    public async excluir(codigo: number): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.delete(`${this.endPoint}/${codigo}/empresa/${storeSistema.getters.codigoEmpresaOperacao()}`);
      return result.data;
    }

    public inverterCfop(cfop: number): string {
      let cfopOperacao = String(cfop);
      cfopOperacao = cfopOperacao.substring(1, cfopOperacao.length);
      switch (String(cfop).substring(0, 1)) {
        case '1':
          cfopOperacao = `5${cfopOperacao}`;
          break;
        case '2':
          cfopOperacao = `6${cfopOperacao}`;
          break;
        case '3':
          cfopOperacao = `7${cfopOperacao}`;
          break;
        case '5':
          cfopOperacao = `1${cfopOperacao}`;
          break;
        case '6':
          cfopOperacao = `2${cfopOperacao}`;
          break;
        case '7':
          cfopOperacao = `3${cfopOperacao}`;
          break;
        default:
          cfopOperacao = String(cfop);
          break;
      }
      return cfopOperacao;
    }
}
export default ServicoCfop;
