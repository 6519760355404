import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ant-row" }
const _hoisted_2 = { class: "ant-col ant-col-xs-24 ant-col-xl-4" }
const _hoisted_3 = { class: "ant-col ant-col-xs-24 ant-col-xl-4" }
const _hoisted_4 = { class: "ant-col ant-col-xs-24 ant-col-xl-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_campo_numerico = _resolveComponent("campo-numerico")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_form, { layout: "vertical" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_a_form_item, {
              label: "Desconto",
              class: "ss-margin-campos"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_campo_numerico, {
                  monetario: true,
                  quantidadeCasasDecimais: 2,
                  valor: _ctx.computedNotaFiscal.totalDesconto,
                  "onUpdate:valor": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedNotaFiscal.totalDesconto) = $event)),
                  disabled: !_ctx.props.editavel,
                  onValorDigitado: _cache[1] || (_cache[1] = ($event: any) => (_ctx.descontoAlterado()))
                }, null, 8, ["valor", "disabled"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_a_form_item, {
              label: "Seguro",
              class: "ss-margin-campos"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_campo_numerico, {
                  monetario: true,
                  quantidadeCasasDecimais: 2,
                  valor: _ctx.computedNotaFiscal.totalSeguro,
                  "onUpdate:valor": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.computedNotaFiscal.totalSeguro) = $event)),
                  disabled: !_ctx.props.editavel,
                  onValorDigitado: _cache[3] || (_cache[3] = ($event: any) => (_ctx.totalAlterado()))
                }, null, 8, ["valor", "disabled"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_a_form_item, {
              label: "Outras Despesas",
              class: "ss-margin-campos"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_campo_numerico, {
                  monetario: true,
                  quantidadeCasasDecimais: 2,
                  valor: _ctx.computedNotaFiscal.totalOutrasDespesas,
                  "onUpdate:valor": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.computedNotaFiscal.totalOutrasDespesas) = $event)),
                  disabled: !_ctx.props.editavel,
                  onValorDigitado: _cache[5] || (_cache[5] = ($event: any) => (_ctx.totalAlterado()))
                }, null, 8, ["valor", "disabled"])
              ]),
              _: 1
            })
          ])
        ])
      ]),
      _: 1
    })
  ]))
}