
import { computed, defineComponent, reactive } from 'vue';
import { IOptionString } from '@/core/models/AntDesign/IOption';
import { useTelaBase } from '@/core/composables/TelaBase';
import ServicoCenarioFiscal from '@/servicos/Cadastros/Tributacoes/ServicoCenarioFiscal';

export default defineComponent({
  name: 'SelecionarCstIcms',
  props: {
    varios: {
      type: Boolean,
      default: false,
    },
    codigoSelecionado: {
      type: String || undefined,
    },
    codigosSelecionados: {
      type: Array as () => string[],
      default: () => [],
    },
    placeholder: {
      type: String,
      default: 'Selecione',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:codigoSelecionado', 'update:codigosSelecionados', 'change'],
  setup(props, { emit }) {
    const { montaOpcoesComListaItemGenericoTexto } = useTelaBase();

    const state = reactive({
      listaCstIcms: [] as IOptionString[],
    });

    (async () => {
      const listaCstIcms = await new ServicoCenarioFiscal().obterListaCstIcms();
      state.listaCstIcms = montaOpcoesComListaItemGenericoTexto(listaCstIcms);
    })();

    function obterCsts():any {
      return props.codigoSelecionado;
    }

    const computedCodigoSelecionado = computed({
      get: () => obterCsts(),
      set: (val: string) => {
        emit('update:codigoSelecionado', val);
      },
    });

    const computedCodigosSelecionados = computed({
      get: () => props.codigosSelecionados,
      set: (val: string[]) => {
        emit('update:codigosSelecionados', val);
      },
    });

    function change() {
      emit('change', props.varios ? props.codigosSelecionados : props.codigoSelecionado);
    }

    return {
      props,
      state,
      computedCodigoSelecionado,
      computedCodigosSelecionados,
      change,
    };
  },
});
