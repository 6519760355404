import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ant-row" }
const _hoisted_2 = { class: "ant-col ant-col-xs-12 ant-col-xl-12" }
const _hoisted_3 = { class: "ant-col ant-col-xs-12 ant-col-xl-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_campo_numerico = _resolveComponent("campo-numerico")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_selecionar_generico = _resolveComponent("selecionar-generico")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_a_form_item, {
        label: "Valor",
        class: "ss-margin-campos"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_campo_numerico, {
            monetario: true,
            quantidadeCasasDecimais: 2,
            valorMaximo: _ctx.props.valorRestante,
            valor: _ctx.computedValorParcelado,
            "onUpdate:valor": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedValorParcelado) = $event))
          }, null, 8, ["valorMaximo", "valor"])
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_a_form_item, {
        label: "Qtd de Parcelas",
        class: "ss-margin-campos"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_selecionar_generico, {
            itens: _ctx.props.parcelas,
            valor: _ctx.computedQtdParcelas,
            "onUpdate:valor": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.computedQtdParcelas) = $event)),
            limparSelecao: false
          }, null, 8, ["itens", "valor"])
        ]),
        _: 1
      })
    ])
  ]))
}