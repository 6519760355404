
import {
  computed, defineComponent, onBeforeMount, reactive,
} from 'vue';
import { Modal } from 'ant-design-vue';
import Icone from '@/core/components/Icone.vue';
import { IColumn } from '@/core/models/AntDesign/IColumn';
import DevolucaoVendaItemModal from '@/views/Vendas/DevolucoesVendas/DevolucaoVendaItemModal.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import UtilitarioDispositivo from '@/core/utilitarios/UtilitarioDispositivo';
import BuscarItensVendas from '../BuscarItensVendas.vue';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { EOperadorLogico } from '@/core/models/Enumeradores/EOperadorLogico';
import { EStatusRecebimentoMercadoria } from '@/models/Enumeradores/Compras/EStatusRecebimentoMercadoria';
import { INotaFiscalDocumentoReferenciadoDfe } from '@/models/Entidades/Fiscal/INotaFiscal';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import SelecionarUnidade from '@/components/Cadastros/Produtos/Unidades/SelecionarUnidade.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import { IEstoque } from '@/models/Entidades/Cadastros/Estoques/IEstoque';
import { IDevolucaoVendaItem } from '@/models/Entidades/Vendas/IDevolucaoVenda';
import ServicoVendas from '@/servicos/Vendas/ServicoVendas';
import { IDTOVendaItem } from '@/models/DTO/Vendas/IDTOVenda';
import { EStatusVenda } from '@/models/Enumeradores/Vendas/EStatusVenda';

export default defineComponent({
  name: 'DevolucaoVendaItens',
  props: {
    empresa: {
      type: Number,
      required: true,
    },
    pessoa: {
      type: Number,
      required: true,
    },
    totalNota: {
      type: Number,
      required: true,
    },
    estoquesDisponiveis: {
      type: Array as () => IEstoque[],
      required: true,
    },
    itens: {
      type: Array as () => IDevolucaoVendaItem[],
      required: true,
    },
    documentos: {
      type: Array as () => INotaFiscalDocumentoReferenciadoDfe[],
      required: true,
    },
    editavel: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    Icone,
    MensagemSemDados,
    DevolucaoVendaItemModal,
    BuscarItensVendas,
    SelecionarUnidade,
    CampoNumerico,
  },
  emits: ['update:itens', 'update:documentos', 'incluirItens', 'atualizarItem', 'excluirItem'],
  setup(props, { emit }) {
    const servicoVendas = new ServicoVendas();

    const state = reactive({
      colunasGrade: [] as IColumn[],
      indexItemSelecionado: -1,
      itemSelecionado: {} as IDevolucaoVendaItem,
      filtros: [] as IFiltroGenerico[],
      existeProximo: false,
      existeAnterior: false,
      exibirDetalhesItem: false,
      exibirBuscarItem: false,
      carregandoItens: false,
      telaMobile: false,
    });

    const computedItens = computed({
      get: () => props.itens,
      set: (val: IDevolucaoVendaItem[]) => {
        emit('update:itens', val);
      },
    });

    const computedDocumentos = computed({
      get: () => props.documentos,
      set: (val: INotaFiscalDocumentoReferenciadoDfe[]) => {
        emit('update:documentos', val);
      },
    });

    function preencherColunas() {
      if (state.telaMobile) {
        state.colunasGrade = [
          {
            title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 1, visible: false,
          },
          {
            title: 'Item', dataIndex: 'item', key: 'Item', position: 2, visible: true, ellipsis: true,
          },
        ];
      } else {
        state.colunasGrade = [
          {
            title: 'Produto', dataIndex: 'produto', key: 'Produto', position: 0, visible: true, width: 15, ordering: true, align: 'left', ellipsis: true,
          },
          {
            title: 'Unidade', dataIndex: 'unidade', key: 'Unidade', position: 1, visible: true, width: 4, ordering: false, align: 'center', ellipsis: true,
          },
          {
            title: 'Quantidade', dataIndex: 'quantidade', key: 'Quantidade', position: 2, visible: true, ordering: false, align: 'right', width: 4, ellipsis: true,
          },
          {
            title: 'V. Unitário', dataIndex: 'valorUnitario', key: 'ValorUnitario', position: 3, visible: true, ordering: false, align: 'right', width: 5, ellipsis: true,
          },
          {
            title: 'ICMS', dataIndex: 'icms', key: 'Icms', position: 4, visible: true, align: 'right', width: 4, ellipsis: true,
          },
          {
            title: 'IPI', dataIndex: 'ipi', key: 'Ipi', position: 5, visible: true, ordering: false, align: 'right', width: 4, ellipsis: true,
          },
          {
            title: 'Desconto', dataIndex: 'desconto', key: 'Desconto', position: 6, visible: true, ordering: false, align: 'right', width: 5, ellipsis: true,
          },
          {
            title: 'Total', dataIndex: 'total', key: 'Total', position: 7, visible: true, ordering: false, align: 'right', width: 5, ellipsis: true,
          },
          {
            title: 'Ações', key: 'acoes', position: 9, visible: true, fixed: 'right', width: 3, align: 'center', ellipsis: true,
          },
        ];
      }
    }

    function validarExisteProximoItem() {
      state.existeProximo = state.indexItemSelecionado < computedItens.value.length - 1;
    }

    function validarExisteItemAnterior() {
      state.existeAnterior = state.indexItemSelecionado > 0;
    }

    function selecionarItens() {
      state.exibirBuscarItem = true;
    }

    async function adicionarItens(itens: IDTOVendaItem[]) {
      state.carregandoItens = true;
      const codigosSelecionados = [] as number[];
      state.indexItemSelecionado = -1;
      itens.forEach((item) => {
        const indexItem = computedItens.value.findIndex((i) => i.codigoVendaItem === item.codigo);
        if (indexItem === -1) {
          codigosSelecionados.push(item.codigo);
        }
      });

      const itensVenda = await servicoVendas.obterVariosItensCompletos(props.empresa, codigosSelecionados);
      itens.forEach((item) => {
        const indexItem = computedItens.value.findIndex((i) => i.codigoVendaItem === item.codigo);
        if (indexItem === -1) {
          const indexItemRecebimento = itensVenda.findIndex((ir) => ir.codigo === item.codigo);
          const itemNovo = {} as IDevolucaoVendaItem;
          if (UtilitarioGeral.valorValido(item.chaveAcesso)) {
            const documentoReferenciado = {} as INotaFiscalDocumentoReferenciadoDfe;

            documentoReferenciado.codigoNotaFiscal = 0;
            documentoReferenciado.codigoNotaFiscalReferenciada = item.codigoNotaFiscal;
            documentoReferenciado.modelo = item.modelo;
            documentoReferenciado.chaveAcesso = item.chaveAcesso;
            computedDocumentos.value.push(documentoReferenciado);
          }
          itemNovo.codigoVendaItem = item.codigo;
          itemNovo.itemNotaFiscal = itensVenda[indexItemRecebimento].itemNotaFiscal;
          itemNovo.itemNotaFiscal.codigo = 0;
          itemNovo.itemNotaFiscal.codigoNotaFiscal = 0;
          itemNovo.itemNotaFiscal.combustivel.codigo = 0;
          itemNovo.itemNotaFiscal.combustivel.codigoNotaFiscalItem = 0;
          itemNovo.itemNotaFiscal.medicamento.codigo = 0;
          itemNovo.itemNotaFiscal.medicamento.codigoNotaFiscalItem = 0;
          itemNovo.itemNotaFiscal.impostos.codigo = 0;
          itemNovo.itemNotaFiscal.impostos.codigoNotaFiscalItem = 0;
          itemNovo.itemNotaFiscal.impostos.icms.codigo = 0;
          itemNovo.itemNotaFiscal.impostos.icms.codigoNotaFiscalItemImposto = 0;
          itemNovo.itemNotaFiscal.impostos.ipi.codigo = 0;
          itemNovo.itemNotaFiscal.impostos.ipi.codigoNotaFiscalItemImposto = 0;
          itemNovo.itemNotaFiscal.impostos.pis.codigo = 0;
          itemNovo.itemNotaFiscal.impostos.pis.codigoNotaFiscalItemImposto = 0;
          itemNovo.itemNotaFiscal.impostos.cofins.codigo = 0;
          itemNovo.itemNotaFiscal.impostos.cofins.codigoNotaFiscalItemImposto = 0;
          itemNovo.itemNotaFiscal.impostos.st.codigo = 0;
          itemNovo.itemNotaFiscal.impostos.st.codigoNotaFiscalItemImposto = 0;
          itemNovo.itemNotaFiscal.impostos.importacao.codigo = 0;
          itemNovo.itemNotaFiscal.impostos.importacao.codigoNotaFiscalItemImposto = 0;
          itemNovo.itemNotaFiscal.estoques.forEach((e, index) => {
            itemNovo.itemNotaFiscal.estoques[index].codigo = 0;
            itemNovo.itemNotaFiscal.estoques[index].codigoNotaFiscalItem = 0;
            itemNovo.itemNotaFiscal.estoques[index].codigoMovimentoEstoque = 0;
          });
          computedItens.value.push(itemNovo);
        }
      });
      emit('incluirItens');
      state.carregandoItens = false;
    }

    function editarItem(index: number) {
      state.indexItemSelecionado = index;
      state.itemSelecionado = computedItens.value[index];
      validarExisteProximoItem();
      validarExisteItemAnterior();
      state.exibirDetalhesItem = true;
    }

    function proximoItem() {
      editarItem(state.indexItemSelecionado + 1);
    }

    function itemAnterior() {
      editarItem(state.indexItemSelecionado - 1);
    }

    function excluirItem(index: number) {
      const codigoItem = computedItens.value[index].codigo;
      computedItens.value.splice(index, 1);
      emit('excluirItem', codigoItem);
    }

    async function confirmarExclusao(index: number) {
      Modal.confirm({
        title: 'Você confirma a exclusão do item',
        content: `${index + 1} ?`,
        okText: 'Sim',
        okType: 'danger',
        cancelText: 'Não',
        autoFocusButton: null,
        onOk: async () => { await excluirItem(index); },
      });
    }

    function salvarItem(item: IDevolucaoVendaItem) {
      if (state.indexItemSelecionado > -1) {
        computedItens.value[state.indexItemSelecionado] = item;
      } else {
        computedItens.value.push(item);
        state.indexItemSelecionado = computedItens.value.length - 1;
      }
      state.exibirDetalhesItem = false;
      emit('atualizarItem', state.indexItemSelecionado);
    }

    function preencherFiltrosRecebimento() {
      const filtroStatus = {} as IFiltroGenerico;
      filtroStatus.identificador = 'Status';
      filtroStatus.tipoDado = 1;
      filtroStatus.operador = EOperadorLogico.E;
      filtroStatus.condicao = 10;
      filtroStatus.valores = [String(EStatusVenda.EmDigitacao), String(EStatusVenda.Finalizada)];
      state.filtros.push(filtroStatus);
    }

    onBeforeMount(async () => {
      state.telaMobile = UtilitarioDispositivo.larguraTelaMobile();
      preencherColunas();
      preencherFiltrosRecebimento();
    });

    return {
      props,
      state,
      UtilitarioMascara,
      computedItens,
      computedDocumentos,
      proximoItem,
      itemAnterior,
      selecionarItens,
      adicionarItens,
      editarItem,
      confirmarExclusao,
      salvarItem,
    };
  },
});
