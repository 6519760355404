
import {
  computed, defineComponent,
} from 'vue';

export default defineComponent({
  name: 'SelecionarModeloDocumentoFiscal',
  components: {
  },
  props: {
    valor: {
      type: String || undefined,
    },
    placeholder: {
      type: String,
      default: 'Selecione',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    classCss: {
      type: String,
      default: '',
    },
  },
  emits: ['update:valor', 'change', 'blur'],
  setup(props, { emit }) {
    function obterValor():any {
      return (props.valor === '' ? undefined : props.valor);
    }

    const computedValor = computed({
      get: () => obterValor(),
      set: (valor: string) => {
        emit('update:valor', valor);
      },
    });

    function defineValorSelecionado(valor:string) {
      emit('update:valor', valor);
      emit('change', valor);
    }

    function blur() {
      emit('blur');
    }

    return {
      props,
      computedValor,
      defineValorSelecionado,
      blur,
    };
  },
});
