import ApiERP from '@/core/conectores/ApiERP';
import { IListaPaginada } from '@/core/models/Consulta/IListaPaginada';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import { IRetornoRelatorio } from '@/core/models/IRetornoRelatorio';
import { IFiltrosRelatorioVendas } from '@/models/Relatorios/Venda/IFiltrosRelatorioVendas';
import { IDadosRelatorioVendaCliente } from '@/models/Relatorios/Venda/IDadosRelatorioVendaCliente';
import { IDadosRelatorioVendaProduto } from '@/models/Relatorios/Venda/IDadosRelatorioVendaProduto';
import { IDadosRelatorioMargemLucro } from '@/models/Relatorios/Venda/IDadosRelatorioMargemLucro';
import { IDadosRelatorioAnaliseDesconto } from '@/models/Relatorios/Venda/IDadosRelatorioAnaliseDesconto';

/**
 * Serviço de Relatórios de Vendas.
 */

class ServicoRelatoriosVendas {
    endPoint = 'relatorios-vendas';

    apiERP = new ApiERP();

    public requisicaoSistema():void{
      this.apiERP = new ApiERP(true);
    }

    public async obterArquivoVendaCliente(tipoArquivo: ETipoArquivo, filtros: IFiltrosRelatorioVendas):Promise<IRetornoRelatorio> {
      const result: any = await this.apiERP.post(`${this.endPoint}/vendas-por-cliente/arquivo/${tipoArquivo}`, filtros);
      return result.data;
    }

    public async obterVendaCliente(filtros: IFiltrosRelatorioVendas): Promise<IDadosRelatorioVendaCliente> {
      const result: any = await this.apiERP.post(`${this.endPoint}/vendas-por-cliente`, filtros);
      const listaPaginada = {} as IListaPaginada;
      listaPaginada.metaData = JSON.parse(result.headers['x-paginacao']);
      listaPaginada.dados = result.data.itens;
      result.data.itens = listaPaginada;
      return result.data;
    }

    public async obterVendaProduto(filtros: IFiltrosRelatorioVendas): Promise<IDadosRelatorioVendaProduto> {
      const result: any = await this.apiERP.post(`${this.endPoint}/vendas-por-produtos/`, filtros);
      const listaPaginada = {} as IListaPaginada;
      listaPaginada.metaData = JSON.parse(result.headers['x-paginacao']);
      listaPaginada.dados = result.data.itens;
      result.data.itens = listaPaginada;
      return result.data;
    }

    public async obterArquivoVendaProduto(tipoArquivo: ETipoArquivo, filtros: IFiltrosRelatorioVendas):Promise<IRetornoRelatorio> {
      const result: any = await this.apiERP.post(`${this.endPoint}/vendas-por-produtos/arquivo/${tipoArquivo}`, filtros);
      return result.data;
    }

    public async obterMargemLucro(filtros: IFiltrosRelatorioVendas): Promise<IDadosRelatorioMargemLucro> {
      const result: any = await this.apiERP.post(`${this.endPoint}/margem-de-lucro`, filtros);
      const listaPaginada = {} as IListaPaginada;
      listaPaginada.metaData = JSON.parse(result.headers['x-paginacao']);
      listaPaginada.dados = result.data.itens;
      result.data.itens = listaPaginada;
      return result.data;
    }

    public async obterArquivoMargemLucro(tipoArquivo: ETipoArquivo, filtros: IFiltrosRelatorioVendas):Promise<IRetornoRelatorio> {
      const result: any = await this.apiERP.post(`${this.endPoint}/margem-de-lucro/arquivo/${tipoArquivo}`, filtros);
      return result.data;
    }

    public async obterAnaliseDesconto(filtros: IFiltrosRelatorioVendas): Promise<IDadosRelatorioAnaliseDesconto> {
      const result: any = await this.apiERP.post(`${this.endPoint}/analise-desconto`, filtros);
      const listaPaginada = {} as IListaPaginada;
      listaPaginada.metaData = JSON.parse(result.headers['x-paginacao']);
      listaPaginada.dados = result.data.itens;
      result.data.itens = listaPaginada;
      return result.data;
    }

    public async obterArquivoAnaliseDesconto(tipoArquivo: ETipoArquivo, filtros: IFiltrosRelatorioVendas):Promise<IRetornoRelatorio> {
      const result: any = await this.apiERP.post(`${this.endPoint}/analise-desconto/arquivo/${tipoArquivo}`, filtros);
      return result.data;
    }
}
export default ServicoRelatoriosVendas;
