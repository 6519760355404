import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ant-row" }
const _hoisted_2 = { class: "ant-col ant-col-xs-24 ant-col-xl-4" }
const _hoisted_3 = { class: "ant-col ant-col-xs-24 ant-col-xl-8" }
const _hoisted_4 = { class: "ant-col ant-col-xs-24 ant-col-xl-12" }
const _hoisted_5 = { class: "ant-row" }
const _hoisted_6 = { class: "ant-col ant-col-xs-24 ant-col-xl-9" }
const _hoisted_7 = { class: "ant-col ant-col-xs-12 ant-col-xl-4" }
const _hoisted_8 = { class: "ant-col ant-col-xs-12 ant-col-xl-4" }
const _hoisted_9 = { class: "ant-col ant-col-xs-12 ant-col-xl-3" }
const _hoisted_10 = { class: "ant-col ant-col-xs-12 ant-col-xl-4" }
const _hoisted_11 = { class: "ant-row" }
const _hoisted_12 = { class: "ant-col ant-col-xs-24 ant-col-xl-10" }
const _hoisted_13 = { class: "ant-col ant-col-xs-24 ant-col-xl-9" }
const _hoisted_14 = { class: "ant-col ant-col-xs-24 ant-col-xl-5" }
const _hoisted_15 = { class: "ant-row" }
const _hoisted_16 = { class: "ant-col ant-col-xs-24 ant-col-xl-9" }
const _hoisted_17 = { class: "ant-col ant-col-xs-12 ant-col-xl-4" }
const _hoisted_18 = { class: "ant-col ant-col-xs-12 ant-col-xl-3" }
const _hoisted_19 = { class: "ant-col ant-col-xs-12 ant-col-xl-4" }
const _hoisted_20 = { class: "ant-col ant-col-xs-12 ant-col-xl-4" }
const _hoisted_21 = { class: "ant-row" }
const _hoisted_22 = { class: "ant-col ant-col-xs-24 ant-col-xl-14" }
const _hoisted_23 = { class: "ant-col ant-col-xs-12 ant-col-xl-5" }
const _hoisted_24 = { class: "ant-col ant-col-xs-12 ant-col-xl-5" }
const _hoisted_25 = { class: "ant-row" }
const _hoisted_26 = { class: "ant-col ant-col-xs-24 ant-col-xl-8" }
const _hoisted_27 = { class: "ant-col ant-col-xs-12 ant-col-xl-4" }
const _hoisted_28 = { class: "ant-col ant-col-xs-12 ant-col-xl-3" }
const _hoisted_29 = { class: "ant-col ant-col-xs-12 ant-col-xl-4" }
const _hoisted_30 = { class: "ant-col ant-col-xs-12 ant-col-xl-5" }
const _hoisted_31 = { class: "ant-row" }
const _hoisted_32 = { class: "ant-col ant-col-xs-24 ant-col-xl-14" }
const _hoisted_33 = { class: "ant-col ant-col-xs-12 ant-col-xl-5" }
const _hoisted_34 = { class: "ant-col ant-col-xs-12 ant-col-xl-5" }
const _hoisted_35 = { class: "ant-row" }
const _hoisted_36 = { class: "ant-col ant-col-xs-24 ant-col-xl-8" }
const _hoisted_37 = { class: "ant-col ant-col-xs-12 ant-col-xl-4" }
const _hoisted_38 = { class: "ant-col ant-col-xs-12 ant-col-xl-3" }
const _hoisted_39 = { class: "ant-col ant-col-xs-12 ant-col-xl-4" }
const _hoisted_40 = { class: "ant-col ant-col-xs-12 ant-col-xl-5" }
const _hoisted_41 = { class: "ant-row" }
const _hoisted_42 = { class: "ant-col ant-col-xs-24 ant-col-xl-9" }
const _hoisted_43 = { class: "ant-col ant-col-xs-24 ant-col-xl-3" }
const _hoisted_44 = { class: "ant-col ant-col-xs-24 ant-col-xl-12" }
const _hoisted_45 = { class: "ant-row" }
const _hoisted_46 = { class: "ant-col ant-col-xs-12 ant-col-xl-4" }
const _hoisted_47 = { class: "ant-col ant-col-xs-12 ant-col-xl-4" }
const _hoisted_48 = { class: "ant-col ant-col-xs-12 ant-col-xl-4" }
const _hoisted_49 = { class: "ant-col ant-col-xs-12 ant-col-xl-4" }
const _hoisted_50 = { class: "ant-col ant-col-xs-12 ant-col-xl-4" }
const _hoisted_51 = { class: "ant-col ant-col-xs-12 ant-col-xl-4" }
const _hoisted_52 = { class: "ant-row" }
const _hoisted_53 = { class: "ant-col ant-col-xs-12 ant-col-xl-5" }
const _hoisted_54 = { class: "ant-col ant-col-xs-12 ant-col-xl-5" }
const _hoisted_55 = { class: "ant-col ant-col-xs-12 ant-col-xl-5" }
const _hoisted_56 = { class: "ant-col ant-col-xs-12 ant-col-xl-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_radio_button = _resolveComponent("a-radio-button")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_selecionar_cst_icms = _resolveComponent("selecionar-cst-icms")!
  const _component_selecionar_csosn = _resolveComponent("selecionar-csosn")!
  const _component_selecionar_origem_mercadoria = _resolveComponent("selecionar-origem-mercadoria")!
  const _component_selecionar_modalidade_base_calculo_icms = _resolveComponent("selecionar-modalidade-base-calculo-icms")!
  const _component_campo_numerico = _resolveComponent("campo-numerico")!
  const _component_card = _resolveComponent("card")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_selecionar_cst_ipi = _resolveComponent("selecionar-cst-ipi")!
  const _component_selecionar_codigo_enquadramento_ipi = _resolveComponent("selecionar-codigo-enquadramento-ipi")!
  const _component_selecionar_tipo_calculo_imposto = _resolveComponent("selecionar-tipo-calculo-imposto")!
  const _component_selecionar_cst_pis = _resolveComponent("selecionar-cst-pis")!
  const _component_selecionar_cst_cofins = _resolveComponent("selecionar-cst-cofins")!
  const _component_selecionar_modalidade_base_calculo_icms_substituicao = _resolveComponent("selecionar-modalidade-base-calculo-icms-substituicao")!
  const _component_selecionar_cest = _resolveComponent("selecionar-cest")!
  const _component_a_tabs = _resolveComponent("a-tabs")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, { layout: "vertical" }, {
          default: _withCtx(() => [
            _createVNode(_component_a_tabs, {
              class: "ss-card",
              defaultActiveKey: "1"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_tab_pane, {
                  key: "1",
                  tab: "ICMS"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_card, null, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_1, [
                          _createElementVNode("div", _hoisted_2, [
                            _createVNode(_component_a_form_item, {
                              label: " ",
                              class: "ss-margin-campos"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_a_radio_group, {
                                  value: _ctx.state.definirCst,
                                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.definirCst) = $event)),
                                  onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.definePreenchimentoCstCsosn())),
                                  "button-style": "solid"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_radio_button, { value: true }, {
                                      default: _withCtx(() => [
                                        _createTextVNode("CST")
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_a_radio_button, { value: false }, {
                                      default: _withCtx(() => [
                                        _createTextVNode("CSOSN")
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }, 8, ["value"])
                              ]),
                              _: 1
                            })
                          ]),
                          _createElementVNode("div", _hoisted_3, [
                            _createVNode(_component_a_form_item, {
                              label: _ctx.state.definirCst ? 'Código da Situação Tributária' : 'Código de Situação da Operação do Simples Nacional',
                              class: "ss-margin-campos"
                            }, {
                              default: _withCtx(() => [
                                (_ctx.state.definirCst)
                                  ? (_openBlock(), _createBlock(_component_selecionar_cst_icms, {
                                      key: 0,
                                      codigoSelecionado: _ctx.computedImpostos.cst,
                                      "onUpdate:codigoSelecionado": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.computedImpostos.cst) = $event)),
                                      disabled: !_ctx.props.editavel,
                                      onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.calcularTributacoes()))
                                    }, null, 8, ["codigoSelecionado", "disabled"]))
                                  : (_openBlock(), _createBlock(_component_selecionar_csosn, {
                                      key: 1,
                                      codigoSelecionado: _ctx.computedImpostos.cson,
                                      "onUpdate:codigoSelecionado": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.computedImpostos.cson) = $event)),
                                      disabled: !_ctx.props.editavel,
                                      onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.calcularTributacoes()))
                                    }, null, 8, ["codigoSelecionado", "disabled"]))
                              ]),
                              _: 1
                            }, 8, ["label"])
                          ]),
                          _createElementVNode("div", _hoisted_4, [
                            _createVNode(_component_a_form_item, {
                              label: "Origem da Mercadoria",
                              class: "ss-margin-campos"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_selecionar_origem_mercadoria, {
                                  codigoSelecionado: _ctx.computedImpostos.origemMercadoria,
                                  "onUpdate:codigoSelecionado": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.computedImpostos.origemMercadoria) = $event)),
                                  disabled: !_ctx.props.editavel,
                                  onChange: _cache[7] || (_cache[7] = ($event: any) => (_ctx.calcularTributacoes()))
                                }, null, 8, ["codigoSelecionado", "disabled"])
                              ]),
                              _: 1
                            })
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_5, [
                          _createElementVNode("div", _hoisted_6, [
                            _createVNode(_component_a_form_item, {
                              label: "Modalidade da B.C do ICMS",
                              class: "ss-margin-campos"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_selecionar_modalidade_base_calculo_icms, {
                                  codigoSelecionado: _ctx.computedImpostos.icms.modalidadeBaseCalculoIcms,
                                  "onUpdate:codigoSelecionado": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.computedImpostos.icms.modalidadeBaseCalculoIcms) = $event)),
                                  disabled: !_ctx.props.editavel,
                                  onChange: _cache[9] || (_cache[9] = ($event: any) => (_ctx.calcularTributacoes()))
                                }, null, 8, ["codigoSelecionado", "disabled"])
                              ]),
                              _: 1
                            })
                          ]),
                          _createElementVNode("div", _hoisted_7, [
                            _createVNode(_component_a_form_item, {
                              label: "Redução da B.C de ICMS",
                              class: "ss-margin-campos"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_campo_numerico, {
                                  percentual: true,
                                  quantidadeCasasDecimais: 2,
                                  valor: _ctx.computedImpostos.icms.reducaoBaseCalculoIcms,
                                  "onUpdate:valor": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.computedImpostos.icms.reducaoBaseCalculoIcms) = $event)),
                                  disabled: !_ctx.props.editavel,
                                  onChange: _cache[11] || (_cache[11] = ($event: any) => (_ctx.calcularTributacoes()))
                                }, null, 8, ["valor", "disabled"])
                              ]),
                              _: 1
                            })
                          ]),
                          _createElementVNode("div", _hoisted_8, [
                            _createVNode(_component_a_form_item, {
                              label: "B.C do ICMS",
                              class: "ss-margin-campos"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_campo_numerico, {
                                  monetario: true,
                                  quantidadeCasasDecimais: 2,
                                  valor: _ctx.computedImpostos.icms.baseCalculoIcms,
                                  "onUpdate:valor": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.computedImpostos.icms.baseCalculoIcms) = $event)),
                                  disabled: !_ctx.props.editavel,
                                  onChange: _cache[13] || (_cache[13] = ($event: any) => (_ctx.calcularTributacoes()))
                                }, null, 8, ["valor", "disabled"])
                              ]),
                              _: 1
                            })
                          ]),
                          _createElementVNode("div", _hoisted_9, [
                            _createVNode(_component_a_form_item, {
                              label: "Alíquota do ICMS",
                              class: "ss-margin-campos"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_campo_numerico, {
                                  percentual: true,
                                  quantidadeCasasDecimais: 2,
                                  valor: _ctx.computedImpostos.icms.aliquotaIcms,
                                  "onUpdate:valor": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.computedImpostos.icms.aliquotaIcms) = $event)),
                                  disabled: !_ctx.props.editavel,
                                  onChange: _cache[15] || (_cache[15] = ($event: any) => (_ctx.calcularTributacoes()))
                                }, null, 8, ["valor", "disabled"])
                              ]),
                              _: 1
                            })
                          ]),
                          _createElementVNode("div", _hoisted_10, [
                            _createVNode(_component_a_form_item, {
                              label: "Valor do ICMS",
                              class: "ss-margin-campos"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_campo_numerico, {
                                  monetario: true,
                                  quantidadeCasasDecimais: 2,
                                  valor: _ctx.computedImpostos.icms.valorIcms,
                                  "onUpdate:valor": [
                                    _cache[16] || (_cache[16] = ($event: any) => ((_ctx.computedImpostos.icms.valorIcms) = $event)),
                                    _ctx.atualizarTotalImpostos
                                  ],
                                  disabled: !_ctx.props.editavel,
                                  onBlur: _ctx.calcular
                                }, null, 8, ["valor", "disabled", "onUpdate:valor", "onBlur"])
                              ]),
                              _: 1
                            })
                          ])
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_tab_pane, {
                  key: "2",
                  tab: "IPI"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_card, null, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_11, [
                          _createElementVNode("div", _hoisted_12, [
                            _createVNode(_component_a_form_item, {
                              label: "CST - Código da Situação Tributária do IPI",
                              class: "ss-margin-campos"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_selecionar_cst_ipi, {
                                  codigoSelecionado: _ctx.computedImpostos.ipi.cstIpi,
                                  "onUpdate:codigoSelecionado": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.computedImpostos.ipi.cstIpi) = $event)),
                                  disabled: !_ctx.props.editavel,
                                  onChange: _cache[18] || (_cache[18] = ($event: any) => (_ctx.calcularTributacoes()))
                                }, null, 8, ["codigoSelecionado", "disabled"])
                              ]),
                              _: 1
                            })
                          ]),
                          _createElementVNode("div", _hoisted_13, [
                            _createVNode(_component_a_form_item, {
                              label: "Enquadramento Fiscal do IPI",
                              class: "ss-margin-campos"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_selecionar_codigo_enquadramento_ipi, {
                                  codigoSelecionado: _ctx.computedImpostos.ipi.codigoEnquadramentoIpi,
                                  "onUpdate:codigoSelecionado": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.computedImpostos.ipi.codigoEnquadramentoIpi) = $event)),
                                  disabled: !_ctx.props.editavel
                                }, null, 8, ["codigoSelecionado", "disabled"])
                              ]),
                              _: 1
                            })
                          ]),
                          _createElementVNode("div", _hoisted_14, [
                            _createVNode(_component_a_form_item, {
                              label: "Qtd de Unidades Tributáveis",
                              class: "ss-margin-campos"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_campo_numerico, {
                                  quantidadeCasasDecimais: 2,
                                  valor: _ctx.computedImpostos.ipi.quantidadeUnidadeTributavelIpi,
                                  "onUpdate:valor": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.computedImpostos.ipi.quantidadeUnidadeTributavelIpi) = $event)),
                                  disabled: !_ctx.props.editavel,
                                  onChange: _cache[21] || (_cache[21] = ($event: any) => (_ctx.calcularTributacoes()))
                                }, null, 8, ["valor", "disabled"])
                              ]),
                              _: 1
                            })
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_15, [
                          _createElementVNode("div", _hoisted_16, [
                            _createVNode(_component_a_form_item, {
                              label: "Tipo de Cálculo",
                              class: "ss-margin-campos"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_selecionar_tipo_calculo_imposto, {
                                  codigoSelecionado: _ctx.computedImpostos.ipi.tipoCalculoIpi,
                                  "onUpdate:codigoSelecionado": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.computedImpostos.ipi.tipoCalculoIpi) = $event)),
                                  disabled: !_ctx.props.editavel,
                                  onChange: _cache[23] || (_cache[23] = ($event: any) => (_ctx.calcularTributacoes()))
                                }, null, 8, ["codigoSelecionado", "disabled"])
                              ]),
                              _: 1
                            })
                          ]),
                          _createElementVNode("div", _hoisted_17, [
                            _createVNode(_component_a_form_item, {
                              label: "Base de Cálculo do IPI",
                              class: "ss-margin-campos"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_campo_numerico, {
                                  monetario: true,
                                  quantidadeCasasDecimais: 2,
                                  valor: _ctx.computedImpostos.ipi.baseCalculoIpi,
                                  "onUpdate:valor": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.computedImpostos.ipi.baseCalculoIpi) = $event)),
                                  disabled: !_ctx.props.editavel,
                                  onChange: _cache[25] || (_cache[25] = ($event: any) => (_ctx.calcularTributacoes()))
                                }, null, 8, ["valor", "disabled"])
                              ]),
                              _: 1
                            })
                          ]),
                          _createElementVNode("div", _hoisted_18, [
                            _createVNode(_component_a_form_item, {
                              label: "Alíquota do IPI",
                              class: "ss-margin-campos"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_campo_numerico, {
                                  percentual: true,
                                  quantidadeCasasDecimais: 2,
                                  valor: _ctx.computedImpostos.ipi.aliquotaIpi,
                                  "onUpdate:valor": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.computedImpostos.ipi.aliquotaIpi) = $event)),
                                  disabled: !_ctx.props.editavel,
                                  onChange: _cache[27] || (_cache[27] = ($event: any) => (_ctx.calcularTributacoes()))
                                }, null, 8, ["valor", "disabled"])
                              ]),
                              _: 1
                            })
                          ]),
                          _createElementVNode("div", _hoisted_19, [
                            _createVNode(_component_a_form_item, {
                              label: "Valor do IPI",
                              class: "ss-margin-campos"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_campo_numerico, {
                                  monetario: true,
                                  quantidadeCasasDecimais: 2,
                                  valor: _ctx.computedImpostos.ipi.valorIpi,
                                  "onUpdate:valor": [
                                    _cache[28] || (_cache[28] = ($event: any) => ((_ctx.computedImpostos.ipi.valorIpi) = $event)),
                                    _ctx.atualizarTotalImpostos
                                  ],
                                  onBlur: _ctx.calcular,
                                  disabled: !_ctx.props.editavel
                                }, null, 8, ["valor", "onUpdate:valor", "onBlur", "disabled"])
                              ]),
                              _: 1
                            })
                          ]),
                          _createElementVNode("div", _hoisted_20, [
                            _createVNode(_component_a_form_item, {
                              label: "Vlr do IPI por Unid. Trib.",
                              class: "ss-margin-campos"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_campo_numerico, {
                                  monetario: true,
                                  quantidadeCasasDecimais: 2,
                                  valor: _ctx.computedImpostos.ipi.valorUnidadeTributavelIpi,
                                  "onUpdate:valor": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.computedImpostos.ipi.valorUnidadeTributavelIpi) = $event)),
                                  disabled: !_ctx.props.editavel,
                                  onChange: _cache[30] || (_cache[30] = ($event: any) => (_ctx.calcularTributacoes()))
                                }, null, 8, ["valor", "disabled"])
                              ]),
                              _: 1
                            })
                          ])
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_tab_pane, {
                  key: "3",
                  tab: "PIS"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_card, null, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_21, [
                          _createElementVNode("div", _hoisted_22, [
                            _createVNode(_component_a_form_item, {
                              label: "CST - Código da Situação Tributária do PIS",
                              class: "ss-margin-campos"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_selecionar_cst_pis, {
                                  codigoSelecionado: _ctx.computedImpostos.pis.cstPis,
                                  "onUpdate:codigoSelecionado": _cache[31] || (_cache[31] = ($event: any) => ((_ctx.computedImpostos.pis.cstPis) = $event)),
                                  disabled: !_ctx.props.editavel,
                                  onChange: _cache[32] || (_cache[32] = ($event: any) => (_ctx.calcularTributacoes()))
                                }, null, 8, ["codigoSelecionado", "disabled"])
                              ]),
                              _: 1
                            })
                          ]),
                          _createElementVNode("div", _hoisted_23, [
                            _createVNode(_component_a_form_item, {
                              label: "Redução da B.C do PIS",
                              class: "ss-margin-campos"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_campo_numerico, {
                                  percentual: true,
                                  quantidadeCasasDecimais: 2,
                                  valor: _ctx.computedImpostos.pis.reducaoBaseCalculoPis,
                                  "onUpdate:valor": _cache[33] || (_cache[33] = ($event: any) => ((_ctx.computedImpostos.pis.reducaoBaseCalculoPis) = $event)),
                                  disabled: !_ctx.props.editavel,
                                  onChange: _cache[34] || (_cache[34] = ($event: any) => (_ctx.calcularTributacoes()))
                                }, null, 8, ["valor", "disabled"])
                              ]),
                              _: 1
                            })
                          ]),
                          _createElementVNode("div", _hoisted_24, [
                            _createVNode(_component_a_form_item, {
                              label: "Qtd de Un Tributáveis",
                              class: "ss-margin-campos"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_campo_numerico, {
                                  quantidadeCasasDecimais: 2,
                                  valor: _ctx.computedImpostos.pis.quantidadeUnidadeTributavelPis,
                                  "onUpdate:valor": _cache[35] || (_cache[35] = ($event: any) => ((_ctx.computedImpostos.pis.quantidadeUnidadeTributavelPis) = $event)),
                                  disabled: !_ctx.props.editavel,
                                  onChange: _cache[36] || (_cache[36] = ($event: any) => (_ctx.calcularTributacoes()))
                                }, null, 8, ["valor", "disabled"])
                              ]),
                              _: 1
                            })
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_25, [
                          _createElementVNode("div", _hoisted_26, [
                            _createVNode(_component_a_form_item, {
                              label: "Tipo de Cálculo",
                              class: "ss-margin-campos"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_selecionar_tipo_calculo_imposto, {
                                  codigoSelecionado: _ctx.computedImpostos.pis.tipoCalculoPis,
                                  "onUpdate:codigoSelecionado": _cache[37] || (_cache[37] = ($event: any) => ((_ctx.computedImpostos.pis.tipoCalculoPis) = $event)),
                                  disabled: !_ctx.props.editavel,
                                  onChange: _cache[38] || (_cache[38] = ($event: any) => (_ctx.calcularTributacoes()))
                                }, null, 8, ["codigoSelecionado", "disabled"])
                              ]),
                              _: 1
                            })
                          ]),
                          _createElementVNode("div", _hoisted_27, [
                            _createVNode(_component_a_form_item, {
                              label: "Base de Cálculo do PIS",
                              class: "ss-margin-campos"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_campo_numerico, {
                                  monetario: true,
                                  quantidadeCasasDecimais: 2,
                                  valor: _ctx.computedImpostos.pis.baseCalculoPis,
                                  "onUpdate:valor": _cache[39] || (_cache[39] = ($event: any) => ((_ctx.computedImpostos.pis.baseCalculoPis) = $event)),
                                  disabled: !_ctx.props.editavel,
                                  onChange: _cache[40] || (_cache[40] = ($event: any) => (_ctx.calcularTributacoes()))
                                }, null, 8, ["valor", "disabled"])
                              ]),
                              _: 1
                            })
                          ]),
                          _createElementVNode("div", _hoisted_28, [
                            _createVNode(_component_a_form_item, {
                              label: "Alíquota do PIS",
                              class: "ss-margin-campos"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_campo_numerico, {
                                  percentual: true,
                                  quantidadeCasasDecimais: 2,
                                  valor: _ctx.computedImpostos.pis.aliquotaPis,
                                  "onUpdate:valor": _cache[41] || (_cache[41] = ($event: any) => ((_ctx.computedImpostos.pis.aliquotaPis) = $event)),
                                  disabled: !_ctx.props.editavel,
                                  onChange: _cache[42] || (_cache[42] = ($event: any) => (_ctx.calcularTributacoes()))
                                }, null, 8, ["valor", "disabled"])
                              ]),
                              _: 1
                            })
                          ]),
                          _createElementVNode("div", _hoisted_29, [
                            _createVNode(_component_a_form_item, {
                              label: "Valor do PIS",
                              class: "ss-margin-campos"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_campo_numerico, {
                                  monetario: true,
                                  quantidadeCasasDecimais: 2,
                                  valor: _ctx.computedImpostos.pis.valorPis,
                                  "onUpdate:valor": [
                                    _cache[43] || (_cache[43] = ($event: any) => ((_ctx.computedImpostos.pis.valorPis) = $event)),
                                    _ctx.atualizarTotalImpostos
                                  ],
                                  onBlur: _ctx.calcular,
                                  disabled: !_ctx.props.editavel
                                }, null, 8, ["valor", "onUpdate:valor", "onBlur", "disabled"])
                              ]),
                              _: 1
                            })
                          ]),
                          _createElementVNode("div", _hoisted_30, [
                            _createVNode(_component_a_form_item, {
                              label: "Vlr do PIS por Unid. Trib.",
                              class: "ss-margin-campos"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_campo_numerico, {
                                  monetario: true,
                                  quantidadeCasasDecimais: 2,
                                  valor: _ctx.computedImpostos.pis.valorUnidadeTributavelPis,
                                  "onUpdate:valor": _cache[44] || (_cache[44] = ($event: any) => ((_ctx.computedImpostos.pis.valorUnidadeTributavelPis) = $event)),
                                  disabled: !_ctx.props.editavel,
                                  onChange: _cache[45] || (_cache[45] = ($event: any) => (_ctx.calcularTributacoes()))
                                }, null, 8, ["valor", "disabled"])
                              ]),
                              _: 1
                            })
                          ])
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_tab_pane, {
                  key: "4",
                  tab: "COFINS"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_card, null, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_31, [
                          _createElementVNode("div", _hoisted_32, [
                            _createVNode(_component_a_form_item, {
                              label: "CST - Código da Situação Tributária do COFINS",
                              class: "ss-margin-campos"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_selecionar_cst_cofins, {
                                  codigoSelecionado: _ctx.computedImpostos.cofins.cstCofins,
                                  "onUpdate:codigoSelecionado": _cache[46] || (_cache[46] = ($event: any) => ((_ctx.computedImpostos.cofins.cstCofins) = $event)),
                                  disabled: !_ctx.props.editavel,
                                  onChange: _cache[47] || (_cache[47] = ($event: any) => (_ctx.calcularTributacoes()))
                                }, null, 8, ["codigoSelecionado", "disabled"])
                              ]),
                              _: 1
                            })
                          ]),
                          _createElementVNode("div", _hoisted_33, [
                            _createVNode(_component_a_form_item, {
                              label: "Red. da B.C do COFINS",
                              class: "ss-margin-campos"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_campo_numerico, {
                                  percentual: true,
                                  quantidadeCasasDecimais: 2,
                                  valor: _ctx.computedImpostos.cofins.reducaoBaseCalculoCofins,
                                  "onUpdate:valor": _cache[48] || (_cache[48] = ($event: any) => ((_ctx.computedImpostos.cofins.reducaoBaseCalculoCofins) = $event)),
                                  disabled: !_ctx.props.editavel,
                                  onChange: _cache[49] || (_cache[49] = ($event: any) => (_ctx.calcularTributacoes()))
                                }, null, 8, ["valor", "disabled"])
                              ]),
                              _: 1
                            })
                          ]),
                          _createElementVNode("div", _hoisted_34, [
                            _createVNode(_component_a_form_item, {
                              label: "Qtd de Un Tributáveis",
                              class: "ss-margin-campos"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_campo_numerico, {
                                  quantidadeCasasDecimais: 2,
                                  valor: _ctx.computedImpostos.cofins.quantidadeUnidadeTributavelCofins,
                                  "onUpdate:valor": _cache[50] || (_cache[50] = ($event: any) => ((_ctx.computedImpostos.cofins.quantidadeUnidadeTributavelCofins) = $event)),
                                  disabled: !_ctx.props.editavel,
                                  onChange: _cache[51] || (_cache[51] = ($event: any) => (_ctx.calcularTributacoes()))
                                }, null, 8, ["valor", "disabled"])
                              ]),
                              _: 1
                            })
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_35, [
                          _createElementVNode("div", _hoisted_36, [
                            _createVNode(_component_a_form_item, {
                              label: "Tipo de Cálculo",
                              class: "ss-margin-campos"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_selecionar_tipo_calculo_imposto, {
                                  codigoSelecionado: _ctx.computedImpostos.cofins.tipoCalculoCofins,
                                  "onUpdate:codigoSelecionado": _cache[52] || (_cache[52] = ($event: any) => ((_ctx.computedImpostos.cofins.tipoCalculoCofins) = $event)),
                                  disabled: !_ctx.props.editavel,
                                  onChange: _cache[53] || (_cache[53] = ($event: any) => (_ctx.calcularTributacoes()))
                                }, null, 8, ["codigoSelecionado", "disabled"])
                              ]),
                              _: 1
                            })
                          ]),
                          _createElementVNode("div", _hoisted_37, [
                            _createVNode(_component_a_form_item, {
                              label: "B.C do COFINS",
                              class: "ss-margin-campos"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_campo_numerico, {
                                  monetario: true,
                                  quantidadeCasasDecimais: 2,
                                  valor: _ctx.computedImpostos.cofins.baseCalculoCofins,
                                  "onUpdate:valor": _cache[54] || (_cache[54] = ($event: any) => ((_ctx.computedImpostos.cofins.baseCalculoCofins) = $event)),
                                  disabled: !_ctx.props.editavel,
                                  onChange: _cache[55] || (_cache[55] = ($event: any) => (_ctx.calcularTributacoes()))
                                }, null, 8, ["valor", "disabled"])
                              ]),
                              _: 1
                            })
                          ]),
                          _createElementVNode("div", _hoisted_38, [
                            _createVNode(_component_a_form_item, {
                              label: "Alíq. do COFINS",
                              class: "ss-margin-campos"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_campo_numerico, {
                                  percentual: true,
                                  quantidadeCasasDecimais: 2,
                                  valor: _ctx.computedImpostos.cofins.aliquotaCofins,
                                  "onUpdate:valor": _cache[56] || (_cache[56] = ($event: any) => ((_ctx.computedImpostos.cofins.aliquotaCofins) = $event)),
                                  disabled: !_ctx.props.editavel,
                                  onChange: _cache[57] || (_cache[57] = ($event: any) => (_ctx.calcularTributacoes()))
                                }, null, 8, ["valor", "disabled"])
                              ]),
                              _: 1
                            })
                          ]),
                          _createElementVNode("div", _hoisted_39, [
                            _createVNode(_component_a_form_item, {
                              label: "Valor do COFINS",
                              class: "ss-margin-campos"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_campo_numerico, {
                                  monetario: true,
                                  quantidadeCasasDecimais: 2,
                                  valor: _ctx.computedImpostos.cofins.valorCofins,
                                  "onUpdate:valor": [
                                    _cache[58] || (_cache[58] = ($event: any) => ((_ctx.computedImpostos.cofins.valorCofins) = $event)),
                                    _ctx.atualizarTotalImpostos
                                  ],
                                  onBlur: _ctx.calcular,
                                  disabled: !_ctx.props.editavel
                                }, null, 8, ["valor", "onUpdate:valor", "onBlur", "disabled"])
                              ]),
                              _: 1
                            })
                          ]),
                          _createElementVNode("div", _hoisted_40, [
                            _createVNode(_component_a_form_item, {
                              label: "Vlr do COFINS por Unid.",
                              class: "ss-margin-campos"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_campo_numerico, {
                                  monetario: true,
                                  quantidadeCasasDecimais: 2,
                                  valor: _ctx.computedImpostos.pis.valorUnidadeTributavelPis,
                                  "onUpdate:valor": _cache[59] || (_cache[59] = ($event: any) => ((_ctx.computedImpostos.pis.valorUnidadeTributavelPis) = $event)),
                                  disabled: !_ctx.props.editavel
                                }, null, 8, ["valor", "disabled"])
                              ]),
                              _: 1
                            })
                          ])
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_tab_pane, {
                  key: "5",
                  tab: "SUBSTITUIÇÃO TRIBUTÁRIA"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_card, null, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_41, [
                          _createElementVNode("div", _hoisted_42, [
                            _createVNode(_component_a_form_item, {
                              label: "Modalidade da B.C do ICMS ST",
                              class: "ss-margin-campos"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_selecionar_modalidade_base_calculo_icms_substituicao, {
                                  codigoSelecionado: _ctx.computedImpostos.st.modalidadeBaseCalculoSubstituicao,
                                  "onUpdate:codigoSelecionado": _cache[60] || (_cache[60] = ($event: any) => ((_ctx.computedImpostos.st.modalidadeBaseCalculoSubstituicao) = $event)),
                                  disabled: !_ctx.props.editavel,
                                  onChange: _cache[61] || (_cache[61] = ($event: any) => (_ctx.calcularTributacoes()))
                                }, null, 8, ["codigoSelecionado", "disabled"])
                              ]),
                              _: 1
                            })
                          ]),
                          _createElementVNode("div", _hoisted_43, [
                            _createVNode(_component_a_form_item, {
                              label: "MVA",
                              class: "ss-margin-campos"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_campo_numerico, {
                                  percentual: true,
                                  quantidadeCasasDecimais: 2,
                                  valor: _ctx.computedImpostos.st.margemValorAdicionado,
                                  "onUpdate:valor": _cache[62] || (_cache[62] = ($event: any) => ((_ctx.computedImpostos.st.margemValorAdicionado) = $event)),
                                  disabled: !_ctx.props.editavel,
                                  onChange: _cache[63] || (_cache[63] = ($event: any) => (_ctx.calcularTributacoes()))
                                }, null, 8, ["valor", "disabled"])
                              ]),
                              _: 1
                            })
                          ]),
                          _createElementVNode("div", _hoisted_44, [
                            _createVNode(_component_a_form_item, {
                              label: "CEST - Código Escificador da Substituição Tributária",
                              class: "ss-margin-campos"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_selecionar_cest, {
                                  codigoSelecionado: _ctx.computedImpostos.st.cest,
                                  "onUpdate:codigoSelecionado": _cache[64] || (_cache[64] = ($event: any) => ((_ctx.computedImpostos.st.cest) = $event)),
                                  disabled: !_ctx.props.editavel
                                }, null, 8, ["codigoSelecionado", "disabled"])
                              ]),
                              _: 1
                            })
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_45, [
                          _createElementVNode("div", _hoisted_46, [
                            _createVNode(_component_a_form_item, {
                              label: "Pauta do ICMS ST",
                              class: "ss-margin-campos"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_campo_numerico, {
                                  percentual: true,
                                  quantidadeCasasDecimais: 2,
                                  valor: _ctx.computedImpostos.st.pautaIcmsSubstituicao,
                                  "onUpdate:valor": _cache[65] || (_cache[65] = ($event: any) => ((_ctx.computedImpostos.st.pautaIcmsSubstituicao) = $event)),
                                  disabled: !_ctx.props.editavel,
                                  onChange: _cache[66] || (_cache[66] = ($event: any) => (_ctx.calcularTributacoes()))
                                }, null, 8, ["valor", "disabled"])
                              ]),
                              _: 1
                            })
                          ]),
                          _createElementVNode("div", _hoisted_47, [
                            _createVNode(_component_a_form_item, {
                              label: "Alíq. do ICMS Interno",
                              class: "ss-margin-campos"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_campo_numerico, {
                                  percentual: true,
                                  quantidadeCasasDecimais: 2,
                                  valor: _ctx.computedImpostos.st.aliquotaIcmsInterno,
                                  "onUpdate:valor": _cache[67] || (_cache[67] = ($event: any) => ((_ctx.computedImpostos.st.aliquotaIcmsInterno) = $event)),
                                  disabled: !_ctx.props.editavel,
                                  onChange: _cache[68] || (_cache[68] = ($event: any) => (_ctx.calcularTributacoes()))
                                }, null, 8, ["valor", "disabled"])
                              ]),
                              _: 1
                            })
                          ]),
                          _createElementVNode("div", _hoisted_48, [
                            _createVNode(_component_a_form_item, {
                              label: "Red. da B.C do ICMS ST",
                              class: "ss-margin-campos"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_campo_numerico, {
                                  percentual: true,
                                  quantidadeCasasDecimais: 2,
                                  valor: _ctx.computedImpostos.st.reducaoBaseCalculoSubstituicao,
                                  "onUpdate:valor": _cache[69] || (_cache[69] = ($event: any) => ((_ctx.computedImpostos.st.reducaoBaseCalculoSubstituicao) = $event)),
                                  disabled: !_ctx.props.editavel,
                                  onChange: _cache[70] || (_cache[70] = ($event: any) => (_ctx.calcularTributacoes()))
                                }, null, 8, ["valor", "disabled"])
                              ]),
                              _: 1
                            })
                          ]),
                          _createElementVNode("div", _hoisted_49, [
                            _createVNode(_component_a_form_item, {
                              label: "BC do ICMS ST",
                              class: "ss-margin-campos"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_campo_numerico, {
                                  monetario: true,
                                  quantidadeCasasDecimais: 2,
                                  valor: _ctx.computedImpostos.st.baseCalculoSubstituicaoTributaria,
                                  "onUpdate:valor": _cache[71] || (_cache[71] = ($event: any) => ((_ctx.computedImpostos.st.baseCalculoSubstituicaoTributaria) = $event)),
                                  disabled: !_ctx.props.editavel,
                                  onChange: _cache[72] || (_cache[72] = ($event: any) => (_ctx.calcularTributacoes()))
                                }, null, 8, ["valor", "disabled"])
                              ]),
                              _: 1
                            })
                          ]),
                          _createElementVNode("div", _hoisted_50, [
                            _createVNode(_component_a_form_item, {
                              label: "Alíquota do ICMS ST",
                              class: "ss-margin-campos"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_campo_numerico, {
                                  percentual: true,
                                  quantidadeCasasDecimais: 2,
                                  valor: _ctx.computedImpostos.st.aliquotaSubstituicaoTributaria,
                                  "onUpdate:valor": _cache[73] || (_cache[73] = ($event: any) => ((_ctx.computedImpostos.st.aliquotaSubstituicaoTributaria) = $event)),
                                  disabled: !_ctx.props.editavel,
                                  onChange: _cache[74] || (_cache[74] = ($event: any) => (_ctx.calcularTributacoes()))
                                }, null, 8, ["valor", "disabled"])
                              ]),
                              _: 1
                            })
                          ]),
                          _createElementVNode("div", _hoisted_51, [
                            _createVNode(_component_a_form_item, {
                              label: "Vlr do ICMS ST",
                              class: "ss-margin-campos"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_campo_numerico, {
                                  monetario: true,
                                  quantidadeCasasDecimais: 2,
                                  valor: _ctx.computedImpostos.st.valorSubstituicaoTributaria,
                                  "onUpdate:valor": _cache[75] || (_cache[75] = ($event: any) => ((_ctx.computedImpostos.st.valorSubstituicaoTributaria) = $event)),
                                  disabled: !_ctx.props.editavel,
                                  onChange: _cache[76] || (_cache[76] = ($event: any) => (_ctx.calcularTributacoes()))
                                }, null, 8, ["valor", "disabled"])
                              ]),
                              _: 1
                            })
                          ])
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_tab_pane, {
                  key: "6",
                  tab: "IMPORTAÇÃO"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_card, null, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_52, [
                          _createElementVNode("div", _hoisted_53, [
                            _createVNode(_component_a_form_item, {
                              label: "Base de Cálculo",
                              class: "ss-margin-campos"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_campo_numerico, {
                                  monetario: true,
                                  quantidadeCasasDecimais: 2,
                                  valor: _ctx.computedImpostos.importacao.baseCalculoImportacao,
                                  "onUpdate:valor": _cache[77] || (_cache[77] = ($event: any) => ((_ctx.computedImpostos.importacao.baseCalculoImportacao) = $event)),
                                  disabled: !_ctx.props.editavel
                                }, null, 8, ["valor", "disabled"])
                              ]),
                              _: 1
                            })
                          ]),
                          _createElementVNode("div", _hoisted_54, [
                            _createVNode(_component_a_form_item, {
                              label: "Despesas Aduaneiras",
                              class: "ss-margin-campos"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_campo_numerico, {
                                  monetario: true,
                                  quantidadeCasasDecimais: 2,
                                  valor: _ctx.computedImpostos.importacao.despesasAduaneiras,
                                  "onUpdate:valor": _cache[78] || (_cache[78] = ($event: any) => ((_ctx.computedImpostos.importacao.despesasAduaneiras) = $event)),
                                  disabled: !_ctx.props.editavel
                                }, null, 8, ["valor", "disabled"])
                              ]),
                              _: 1
                            })
                          ]),
                          _createElementVNode("div", _hoisted_55, [
                            _createVNode(_component_a_form_item, {
                              label: "Vlr do II",
                              class: "ss-margin-campos"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_campo_numerico, {
                                  monetario: true,
                                  quantidadeCasasDecimais: 2,
                                  valor: _ctx.computedImpostos.importacao.valorImportacao,
                                  "onUpdate:valor": [
                                    _cache[79] || (_cache[79] = ($event: any) => ((_ctx.computedImpostos.importacao.valorImportacao) = $event)),
                                    _ctx.atualizarTotalImpostos
                                  ],
                                  onBlur: _ctx.calcular,
                                  disabled: !_ctx.props.editavel
                                }, null, 8, ["valor", "onUpdate:valor", "onBlur", "disabled"])
                              ]),
                              _: 1
                            })
                          ]),
                          _createElementVNode("div", _hoisted_56, [
                            _createVNode(_component_a_form_item, {
                              label: "Valor do IOF",
                              class: "ss-margin-campos"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_campo_numerico, {
                                  monetario: true,
                                  quantidadeCasasDecimais: 2,
                                  valor: _ctx.computedImpostos.importacao.valorIof,
                                  "onUpdate:valor": _cache[80] || (_cache[80] = ($event: any) => ((_ctx.computedImpostos.importacao.valorIof) = $event)),
                                  disabled: !_ctx.props.editavel
                                }, null, 8, ["valor", "disabled"])
                              ]),
                              _: 1
                            })
                          ])
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}