import UtilitarioData from './UtilitarioData';

export default class UtilitarioFinanceiro {
  static obterDiasAtraso(dataBaixa: string, vencimento: string, diasUteis: boolean): number {
    if (UtilitarioData.converterDataParaDayJs(vencimento) < UtilitarioData.converterDataParaDayJs(dataBaixa)) {
      if (diasUteis) {
        const qtdDias = UtilitarioData.verificaDiferencaDiasEntreDatas(UtilitarioData.converterDataParaDayJs(vencimento), UtilitarioData.converterDataParaDayJs(dataBaixa));
        let qtdDiasUteis = 0;
        let dataAtual = vencimento;
        for (let contador = 1; contador <= qtdDias; contador += 1) {
          dataAtual = UtilitarioData.adicionarDiaNaDataHoraMinutoSegundo(dataAtual, 1);
          if (UtilitarioData.verificarDiaSemana(dataAtual)) {
            qtdDiasUteis += 1;
          }
        }
        return qtdDiasUteis;
      }
      return (UtilitarioData.verificaDiferencaDiasEntreDatas(UtilitarioData.converterDataParaDayJs(vencimento), UtilitarioData.converterDataParaDayJs(dataBaixa)));
    }
    return 0;
  }

  static calcularJuros(valor: number, percentual: number, qtdDias: number): number {
    const percentualJurosDia = (percentual / 30);
    const valorDia = percentualJurosDia * valor;
    const totalJuros = ((valorDia * qtdDias) / 100);
    return parseFloat(totalJuros.toFixed(2));
  }

  static calcularMulta(valor: number, percentual: number): number {
    const totalMulta = ((valor / 100) * percentual);
    return parseFloat(totalMulta.toFixed(2));
  }

  static calcularAcrescimo(valor: number, percentual: number): number {
    const totalAcrescimo = ((valor / 100) * percentual);
    return parseFloat(totalAcrescimo.toFixed(2));
  }

  static calcularDesconto(valor: number, percentual: number): number {
    const totalDesconto = ((valor / 100) * percentual);
    return parseFloat(totalDesconto.toFixed(2));
  }

  static valorDecimal2Casas(valor: number): number {
    return parseFloat(valor.toFixed(2));
  }

  static calcularParcelas(valor: number, qtdParcelas: number): number[] {
    const parcelas: number[] = [];
    const valorExato = valor / qtdParcelas;
    const valorArredondado = Math.round(valorExato * 100) / 100;

    for (let i = 0; i < qtdParcelas - 1; i += 1) {
      parcelas.push(valorArredondado);
    }

    const somaParcelas = valorArredondado * (qtdParcelas - 1);
    const ultimaParcela = Math.round((valor - somaParcelas) * 100) / 100;

    parcelas.push(ultimaParcela);

    return parcelas;
  }
}
