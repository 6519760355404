import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = ["title"]
const _hoisted_2 = { style: {"font-weight":"600","padding":"5px"} }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_popover = _resolveComponent("a-popover")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_popover, {
      visible: _ctx.computedApresentarPrecos,
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.computedApresentarPrecos) = $event)),
      placement: "top",
      trigger: "focus"
    }, {
      content: _withCtx(() => [
        _createVNode(_component_a_spin, {
          spinning: (_ctx.state.tabelasPrecos.length === 0)
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.tabelasPrecos, (tabelaPreco) => {
              return (_openBlock(), _createElementBlock("div", {
                key: tabelaPreco.codigo,
                style: {"min-width":"300px","margin":"6px 0","border":"solid 1px #f5f8f9","border-radius":"4px","width":"100%"}
              }, [
                _createElementVNode("div", {
                  title: tabelaPreco.descricao,
                  style: {"background-color":"#f5f8f9","font-weight":"600","width":"100%"}
                }, [
                  _createElementVNode("span", _hoisted_2, _toDisplayString(tabelaPreco.descricao), 1)
                ], 8, _hoisted_1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tabelaPreco.itens, (itemTabela) => {
                  return (_openBlock(), _createElementBlock("div", {
                    onMouseenter: _cache[0] || (_cache[0] = ($event: any) => (_ctx.computedPopOverHover = true)),
                    onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.computedPopOverHover = false)),
                    key: itemTabela.codigo,
                    style: {"width":"100%","padding":"0px 4px 2px 4px"},
                    class: "ant-row"
                  }, [
                    _createElementVNode("div", {
                      class: "ant-col ant-col-xs-12 ant-col-xl-18",
                      onClick: ($event: any) => (_ctx.definePrecoItemTabelaSelecionado(itemTabela))
                    }, [
                      _createVNode(_component_a_checkbox, {
                        onBlur: _ctx.blur,
                        checked: (_ctx.computedCodigoItemSelecionado === itemTabela.codigo)
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(itemTabela.descricaoTipoPreco), 1)
                        ]),
                        _: 2
                      }, 1032, ["onBlur", "checked"])
                    ], 8, _hoisted_3),
                    _createElementVNode("div", {
                      class: "ant-col ant-col-xs-12 ant-col-xl-6",
                      onClick: ($event: any) => (_ctx.definePrecoItemTabelaSelecionado(itemTabela))
                    }, " R$ " + _toDisplayString(_ctx.UtilitarioMascara.mascararValor(itemTabela.valor,itemTabela.casasDecimaisTipoPreco)), 9, _hoisted_4)
                  ], 32))
                }), 128))
              ]))
            }), 128))
          ]),
          _: 1
        }, 8, ["spinning"])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createElementVNode("div", {
      id: _ctx.props.identificacaoElemento
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 8, _hoisted_5)
  ]))
}