
import {
  Ref, defineComponent, inject, reactive, watch,
} from 'vue';
import Icone from '@/core/components/Icone.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import { IEtapaProcessoVendas, IProcessoVendas } from '@/models/Entidades/Cadastros/Vendas/IProcessoVendas';
import ServicoVendas from '@/servicos/Vendas/ServicoVendas';
import RequisicaoModal from '@/core/components/Modal/RequisicaoModal.vue';
import { EStatusRetornoRequisicao, IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import SelecionarData from '@/core/components/Tela/SelecionarData.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import Card from '@/core/components/Tela/Card.vue';
import { IDTOVenda } from '@/models/DTO/Vendas/IDTOVenda';

export default defineComponent({
  name: 'VendasCancelamentoModal',
  props: {
    visivel: {
      type: Boolean,
    },
    venda: {
      type: Object as () => IDTOVenda,
      required: true,
    },
  },
  components: {
    Icone,
    RequisicaoModal,
    SelecionarData,
    CampoNumerico,
    Card,
  },
  emits: ['update:visivel', 'confirmacao'],
  setup(props, { emit }) {
    const servicoVendas = new ServicoVendas();
    const { telaBase, apresentarMensagemSucesso } = useTelaBase();
    const {
      modalBase, apresentarRetornoRequisicao, apresentarBarraProgresso, ocultarBarraProgresso,
    } = useModalBase(props, emit);
    const processoVendas = inject('VENDAS_PROCESSO_VENDAS') as Ref<IProcessoVendas>;
    const etapa = inject('VENDAS_ETAPA_LANCAMENTO') as Ref<IEtapaProcessoVendas>;

    const state = reactive({
      etapaAnterior: {} as IEtapaProcessoVendas,
      motivoCancelamento: '',
    });

    async function salvar() {
      let retorno: IRetornoRequisicao = { codigoRegistro: 0, status: 0, mensagem: '' };
      apresentarBarraProgresso();
      retorno = await servicoVendas.alterarEtapaVenda(props.venda.codigo, etapa.value.codigo, state.motivoCancelamento);
      ocultarBarraProgresso();
      if (retorno.status === EStatusRetornoRequisicao.Sucesso) {
        emit('confirmacao');
        apresentarMensagemSucesso(retorno.mensagem);
        modalBase.computedVisivel = false;
      } else {
        apresentarRetornoRequisicao(retorno);
      }
    }

    watch(async () => modalBase.computedVisivel, async () => {
      const indexEtapa = processoVendas.value.etapas.findIndex((e) => e.codigo === props.venda.codigoEtapaProcessoVendas);
      state.etapaAnterior = processoVendas.value.etapas[indexEtapa];
      state.motivoCancelamento = '';
    });

    return {
      props,
      state,
      telaBase,
      modalBase,
      etapa,
      salvar,
    };
  },
});
