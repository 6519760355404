import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_select = _resolveComponent("a-select")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.props.varios)
      ? (_openBlock(), _createBlock(_component_a_select, {
          key: 0,
          class: "ss-tamanho-completo",
          value: _ctx.computedCodigosSelecionados,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedCodigosSelecionados) = $event)),
          "show-search": "",
          mode: "multiple",
          allowClear: true,
          placeholder: _ctx.props.placeholder,
          optionFilterProp: "label",
          "not-found-content": _ctx.state.fetching ? undefined : null,
          options: _ctx.state.listaFormasPagamento,
          disabled: _ctx.props.disabled,
          onSearch: _cache[1] || (_cache[1] = ($event: any) => (_ctx.pesquisarTipoDocumentoFinanceiro($event))),
          onBlur: _ctx.blur,
          onChange: _ctx.change
        }, _createSlots({ _: 2 }, [
          (_ctx.state.fetching)
            ? {
                name: "notFoundContent",
                fn: _withCtx(() => [
                  _createVNode(_component_a_spin, { size: "small" })
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["value", "placeholder", "not-found-content", "options", "disabled", "onBlur", "onChange"]))
      : (_openBlock(), _createBlock(_component_a_select, {
          key: 1,
          class: "ss-tamanho-completo",
          value: _ctx.computedCodigoSelecionado,
          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.computedCodigoSelecionado) = $event)),
          "show-search": "",
          allowClear: true,
          placeholder: _ctx.props.placeholder,
          optionFilterProp: "label",
          "not-found-content": _ctx.state.fetching ? undefined : null,
          options: _ctx.state.listaFormasPagamento,
          disabled: _ctx.props.disabled,
          onSearch: _cache[3] || (_cache[3] = ($event: any) => (_ctx.pesquisarTipoDocumentoFinanceiro($event))),
          onBlur: _ctx.blur,
          onChange: _ctx.change
        }, _createSlots({ _: 2 }, [
          (_ctx.state.fetching)
            ? {
                name: "notFoundContent",
                fn: _withCtx(() => [
                  _createVNode(_component_a_spin, { size: "small" })
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["value", "placeholder", "not-found-content", "options", "disabled", "onBlur", "onChange"]))
  ]))
}