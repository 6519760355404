
import {
  computed, defineComponent, onBeforeMount, reactive,
} from 'vue';
import { Modal } from 'ant-design-vue';
import { INotaFiscalDetalhePagamento, INotaFiscalDetalhePagamentoAnexo, INotaFiscalDetalhePagamentoCentroCusto } from '@/models/Entidades/Fiscal/INotaFiscal';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import { IColumn } from '@/core/models/AntDesign/IColumn';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import VendaDetalhePagamentoModal from './VendaDetalhePagamentoModal.vue';
import Icone from '@/core/components/Icone.vue';
import UtilitarioDispositivo from '@/core/utilitarios/UtilitarioDispositivo';
import { IVenda } from '@/models/Entidades/Vendas/IVenda';
import UtilitarioFinanceiro from '@/core/utilitarios/UtilitarioFinanceiro';

export default defineComponent({
  name: 'VendaDetalhesPagamento',
  props: {
    editavel: {
      type: Boolean,
      required: true,
    },
    venda: {
      type: Object as () => IVenda,
      required: true,
    },
    totalCaixa: {
      type: Number,
      required: true,
    },
    totalCarteira: {
      type: Number,
      required: true,
    },
    totalOutros: {
      type: Number,
      required: true,
    },
  },
  components: {
    Icone,
    MensagemSemDados,
    CampoNumerico,
    VendaDetalhePagamentoModal,
  },
  emits: ['update:totalDetalhesPagamento', 'update:detalhesPagamento', 'update:totalCaixa', 'update:totalCarteira', 'update:totalOutros', 'adicionarTaxas', 'removerTaxas'],
  setup(props, { emit }) {
    const state = reactive({
      colunasGrade: [] as IColumn[],
      indexSelecionado: -1,
      detalheSelecionado: {} as INotaFiscalDetalhePagamento,
      exibirLancamento: false,
      telaMobile: false,
    });

    const computedDetalhesPagamento = computed({
      get: () => props.venda.notaFiscal.detalhesPagamento,
      set: (val: INotaFiscalDetalhePagamento[]) => {
        emit('update:detalhesPagamento', val);
      },
    });

    const computedTotalCaixa = computed({
      get: () => props.totalCaixa,
      set: (val: number) => {
        emit('update:totalCaixa', val);
      },
    });

    const computedTotalCarteira = computed({
      get: () => props.totalCarteira,
      set: (val: number) => {
        emit('update:totalCarteira', val);
      },
    });

    const computedTotalOutros = computed({
      get: () => props.totalOutros,
      set: (val: number) => {
        emit('update:totalOutros', val);
      },
    });

    function preencherColunas() {
      if (state.telaMobile) {
        state.colunasGrade = [
          {
            title: 'Código', dataIndex: 'codigo', key: 'Codigo', position: 1, visible: false,
          },
          {
            title: 'Detalhe', dataIndex: 'detalhe', key: 'DetalhePagamento', position: 2, visible: true, ellipsis: true,
          },
        ];
      } else {
        state.colunasGrade = [
          {
            title: 'N.Identificação', dataIndex: 'numeroTitulo', key: 'NumeroTitulo', position: 0, visible: true, width: 100, ordering: true, align: 'left', ellipsis: true,
          },
          {
            title: 'T.Documento', dataIndex: 'tipoDocumentoFinanceiro', key: 'TipoDocumentoFinanceiro', position: 1, visible: true, width: 120, ordering: false, align: 'left', ellipsis: true,
          },
          {
            title: 'Vencimento', dataIndex: 'vencimento', key: 'Vencimento', position: 3, visible: true, ordering: false, align: 'center', width: 100, ellipsis: true,
          },
          {
            title: 'Valor', dataIndex: 'valorPagamento', key: 'ValorPagamento', position: 4, visible: true, ordering: false, align: 'right', width: 100, ellipsis: true,
          },
          {
            title: 'Acréscimos', dataIndex: 'acrescimos', key: 'Acrescimos', position: 5, visible: true, ordering: false, align: 'right', width: 100, ellipsis: true,
          },
          {
            title: 'Outras Taxas', dataIndex: 'outrasTaxas', key: 'OutrasTaxas', position: 6, visible: true, ordering: false, align: 'right', width: 100, ellipsis: true,
          },
          {
            title: 'Total', dataIndex: 'valorTotal', key: 'ValorTotal', position: 7, visible: true, ordering: false, align: 'right', width: 100, ellipsis: true,
          },
          {
            title: 'Conta', dataIndex: 'conta', key: 'Conta', position: 8, visible: true, align: 'left', ellipsis: true,
          },
          {
            title: 'Categoria', dataIndex: 'categoria', key: 'Categoria', position: 9, visible: true, ordering: false, align: 'left', width: 250, ellipsis: true,
          },
          {
            title: 'Ações', key: 'acoes', position: 10, visible: true, width: 70, align: 'center', ellipsis: true,
          },
        ];
      }
    }

    function atualizarTotalizadores() {
      let totalCaixa = 0;
      let totalCarteira = 0;
      let totalOutros = 0;
      computedDetalhesPagamento.value.forEach((d) => {
        if (d.gerarCaixa) {
          totalCaixa += d.valorTotal;
        } else if (d.gerarCarteira) {
          totalCarteira += d.valorTotal;
        } else {
          totalOutros += d.valorTotal;
        }
      });

      computedTotalCaixa.value = totalCaixa;
      computedTotalCarteira.value = totalCarteira;
      computedTotalOutros.value = totalOutros;
    }

    function adicionarDetalhe() {
      state.indexSelecionado = -1;
      state.detalheSelecionado = {} as INotaFiscalDetalhePagamento;
      state.detalheSelecionado.alterado = false;
      state.detalheSelecionado.centrosCustos = [] as INotaFiscalDetalhePagamentoCentroCusto[];
      state.detalheSelecionado.anexos = [] as INotaFiscalDetalhePagamentoAnexo[];
      state.exibirLancamento = true;
    }

    function editarDetalhe(index: number) {
      state.indexSelecionado = index;
      state.detalheSelecionado = computedDetalhesPagamento.value[index];
      state.exibirLancamento = true;
    }

    function atualizaParcelas(qtdParcelas: number, valorTotal: number) {
      const parcelas = UtilitarioFinanceiro.calcularParcelas(valorTotal, qtdParcelas - 1);
      for (let i = 0; i < qtdParcelas - 1; i += 1) {
        computedDetalhesPagamento.value[i].valorPagamento = parcelas[i].valueOf();
        computedDetalhesPagamento.value[i].valorTotal = parcelas[i].valueOf();
        computedDetalhesPagamento.value[i].alterado = true;
      }
    }

    function removerDetalhe(index: number, removerTudo: boolean) {
      const codigoTipoDocumentoFinanceiro = computedDetalhesPagamento.value[index].codigoTipoDocumentoFinanceiro;
      let totalAcrescimos = computedDetalhesPagamento.value[index].acrescimos;
      let totalOutrasTaxas = computedDetalhesPagamento.value[index].outrasTaxas;
      computedDetalhesPagamento.value.splice(index, 1);
      if (removerTudo) {
        for (let i = computedDetalhesPagamento.value.length - 1; i >= 0; i += -1) {
          if (computedDetalhesPagamento.value[i].codigoTipoDocumentoFinanceiro === codigoTipoDocumentoFinanceiro) {
            totalAcrescimos += computedDetalhesPagamento.value[i].acrescimos;
            totalOutrasTaxas += computedDetalhesPagamento.value[i].outrasTaxas;
            computedDetalhesPagamento.value.splice(i, 1);
          }
        }
      }

      emit('removerTaxas', totalAcrescimos, totalOutrasTaxas);
    }

    function excluirDetalhe(index: number) {
      let qtdParcelas = 0;
      let totalAcrescimos = computedDetalhesPagamento.value[index].acrescimos;
      let totalOutrasTaxas = computedDetalhesPagamento.value[index].outrasTaxas;
      let valorTotal = 0;

      computedDetalhesPagamento.value.forEach((d, indexD) => {
        if (d.codigoTipoDocumentoFinanceiro === computedDetalhesPagamento.value[index].codigoTipoDocumentoFinanceiro) {
          qtdParcelas += 1;
          totalAcrescimos += computedDetalhesPagamento.value[indexD].acrescimos;
          totalOutrasTaxas += computedDetalhesPagamento.value[indexD].outrasTaxas;
          valorTotal += computedDetalhesPagamento.value[indexD].valorTotal;
        }
      });

      console.log('Total Acrescimos: ', totalAcrescimos);
      console.log('Total Taxas: ', totalOutrasTaxas);

      if (qtdParcelas > 1) {
        if (totalAcrescimos > 0 || totalOutrasTaxas > 0) {
          Modal.confirm({
            title: 'Você realmente deseja excluir o detalhe selecionado e os relacionados?',
            content: `O detalhe de número ${computedDetalhesPagamento.value[index].numeroTitulo} faz parte de um parcelamento de ${qtdParcelas} parcelas!`,
            okText: 'Sim',
            okType: 'danger',
            cancelText: 'Não',
            autoFocusButton: null,
            onOk: () => {
              removerDetalhe(index, true);
              atualizarTotalizadores();
            },
          });
        } else {
          Modal.confirm({
            title: 'Você realmente deseja excluir o detalhe selecionado e os relacionados?',
            content: `O detalhe de número ${computedDetalhesPagamento.value[index].numeroTitulo} faz parte de um parcelamento de ${qtdParcelas} parcelas!`,
            okText: 'Sim',
            okType: 'danger',
            cancelText: 'Não',
            autoFocusButton: null,
            onOk: () => {
              removerDetalhe(index, true);
              atualizarTotalizadores();
            },
            onCancel: () => {
              Modal.confirm({
                title: 'Deseja recalcular as parcelas?',
                content: 'O valor das parcelas será alterado de acordo com as parcelas restantes!',
                okText: 'Sim',
                okType: 'danger',
                cancelText: 'Não',
                autoFocusButton: null,
                onOk: () => {
                  removerDetalhe(index, false);
                  atualizaParcelas(qtdParcelas, valorTotal);
                  atualizarTotalizadores();
                },
                onCancel: () => {
                  removerDetalhe(index, false);
                  atualizarTotalizadores();
                },
              });
            },
          });
        }
      } else {
        Modal.confirm({
          title: 'Você realmente deseja excluir o detalhe selecionado?',
          content: `Detalhe Número: ${computedDetalhesPagamento.value[index].numeroTitulo}`,
          okText: 'Sim',
          okType: 'danger',
          cancelText: 'Não',
          autoFocusButton: null,
          onOk: () => {
            removerDetalhe(index, false);
            atualizarTotalizadores();
          },
        });
      }
    }

    function adicionarTaxas(acrescimos: number, outrasTaxas: number) {
      atualizarTotalizadores();
      emit('adicionarTaxas', acrescimos, outrasTaxas);
    }

    onBeforeMount(async () => {
      state.telaMobile = UtilitarioDispositivo.larguraTelaMobile();
      preencherColunas();
    });

    return {
      props,
      state,
      UtilitarioMascara,
      UtilitarioData,
      computedDetalhesPagamento,
      computedTotalCaixa,
      computedTotalCarteira,
      computedTotalOutros,
      atualizarTotalizadores,
      adicionarDetalhe,
      editarDetalhe,
      excluirDetalhe,
      adicionarTaxas,
      atualizaParcelas,
    };
  },
});
