
import { defineComponent, reactive, computed } from 'vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import SelecionarGenerico from '@/core/components/Tela/SelecionarGenerico.vue';
import { IOption } from '@/core/models/AntDesign/IOption';

export default defineComponent({
  name: 'VendaPagamentoParcelado',
  props: {
    parcelas: {
      type: Array as () => IOption[],
      required: true,
    },
    qtdParcelas: {
      type: Number,
      required: true,
    },
    valorRestante: {
      type: Number,
      required: true,
    },
    valorParcelado: {
      type: Number,
      required: true,
    },
  },
  components: {
    CampoNumerico,
    SelecionarGenerico,
  },
  emits: ['update:qtdParcelas', 'update:valorParcelado'],
  setup(props, { emit }) {
    const state = reactive({});

    const computedQtdParcelas = computed({
      get: () => props.qtdParcelas,
      set: (val: number) => {
        emit('update:qtdParcelas', val);
      },
    });

    const computedValorParcelado = computed({
      get: () => props.valorParcelado,
      set: (val: number) => {
        emit('update:valorParcelado', val);
      },
    });

    return {
      props,
      state,
      computedQtdParcelas,
      computedValorParcelado,
    };
  },
});
