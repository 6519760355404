
import {
  defineComponent, reactive, computed, watch, onBeforeMount,
} from 'vue';
import Icone from '@/core/components/Icone.vue';
import SelecionarModeloDocumentoFiscal from '@/components/Compartilhados/SelecionarModeloDocumentoFiscal.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import { INotaFiscalDocumentoReferenciadoDfe } from '@/models/Entidades/Fiscal/INotaFiscal';
import UtilitarioDispositivo from '@/core/utilitarios/UtilitarioDispositivo';

export default defineComponent({
  name: 'NotaFiscalDocumentosReferenciados',
  props: {
    visivel: {
      type: Boolean,
      required: true,
    },
    codigoRegistro: {
      type: Number,
      required: true,
    },
    documentos: {
      type: Array as () => INotaFiscalDocumentoReferenciadoDfe[],
      required: true,
    },
    editavel: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    Icone,
    SelecionarModeloDocumentoFiscal,
    MensagemSemDados,
  },
  emits: ['update:documentos', 'documentosAtualizados'],
  setup(props, { emit }) {
    const { apresentarMensagemAlerta } = useTelaBase();

    const state = reactive({
      modeloSelecionado: '',
      chaveAcessoSelecionada: '',
      telaMobile: false,
    });

    const computedDocumentos = computed({
      get: () => props.documentos,
      set: (documentos: INotaFiscalDocumentoReferenciadoDfe[]) => {
        emit('update:documentos', documentos);
      },
    });

    function limparCampos() {
      state.modeloSelecionado = '';
      state.chaveAcessoSelecionada = '';
    }

    function adicionarDocumento() {
      if (state.modeloSelecionado === undefined || state.modeloSelecionado === '') {
        apresentarMensagemAlerta('Nenhum modelo selecionado!');
        return;
      }

      if (state.chaveAcessoSelecionada === undefined || state.chaveAcessoSelecionada === '') {
        apresentarMensagemAlerta('Nenhum chave de acesso selecionado!');
        return;
      }
      const documentoReferenciado = {} as INotaFiscalDocumentoReferenciadoDfe;
      documentoReferenciado.codigoNotaFiscal = props.codigoRegistro;
      documentoReferenciado.modelo = state.modeloSelecionado;
      documentoReferenciado.chaveAcesso = state.chaveAcessoSelecionada;
      computedDocumentos.value.push(documentoReferenciado);
      limparCampos();
      emit('documentosAtualizados');
    }

    function removerDocumento(index: number) {
      computedDocumentos.value.splice(index, 1);
      emit('documentosAtualizados');
    }

    watch(async () => props.visivel, async () => {
      limparCampos();
    });

    onBeforeMount(async () => {
      state.telaMobile = UtilitarioDispositivo.larguraTelaMobile();
    });

    return {
      props,
      state,
      computedDocumentos,
      adicionarDocumento,
      removerDocumento,
      limparCampos,
    };
  },
});
