
import {
  defineComponent, reactive, watch, computed,
} from 'vue';
import Icone from '@/core/components/Icone.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import { INotaFiscalDetalhePagamento, INotaFiscalDetalhePagamentoAnexo, INotaFiscalDetalhePagamentoCentroCusto } from '@/models/Entidades/Fiscal/INotaFiscal';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import ServicoNotaFiscal from '@/servicos/Fiscal/ServicoNotaFiscal';
import UtilitarioDispositivo from '@/core/utilitarios/UtilitarioDispositivo';
import { IFormaPagamentoTipoDocumentoFinanceiro } from '@/models/Entidades/Cadastros/Financeiro/IFormaPagamento';
import VendaDetalhePagamentoParcelado from './VendaDetalhePagamentoParcelado.vue';
import VendaDetalhePagamentoOutros from './VendaDetalhePagamentoOutros.vue';
import { IOption } from '@/core/models/AntDesign/IOption';
import { IVenda } from '@/models/Entidades/Vendas/IVenda';
import SelecionarTipoDocumentoFinanceiro from '@/components/Cadastros/Financeiro/SelecionarTipoDocumentoFinanceiro.vue';
import UtilitarioData from '@/core/utilitarios/UtilitarioData';
import UtilitarioMatematico from '@/core/utilitarios/UtilitarioMatematico';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import UtilitarioFinanceiro from '@/core/utilitarios/UtilitarioFinanceiro';

export default defineComponent({
  name: 'VendaDetalhePagamentoModal',
  props: {
    visivel: {
      type: Boolean,
    },
    editavel: {
      type: Boolean,
      required: true,
    },
    venda: {
      type: Object as () => IVenda,
      required: true,
    },
    detalhePagamento: {
      type: Object as () => INotaFiscalDetalhePagamento,
      required: true,
    },
  },
  components: {
    Icone,
    VendaDetalhePagamentoParcelado,
    VendaDetalhePagamentoOutros,
    SelecionarTipoDocumentoFinanceiro,
  },
  emits: ['update:visivel', 'update:venda', 'confirmar'],
  setup(props, { emit }) {
    const servicoNotaFiscal = new ServicoNotaFiscal();
    servicoNotaFiscal.requisicaoSistema();
    const { telaBase, apresentarMensagemAlerta } = useTelaBase();
    const { modalBase } = useModalBase(props, emit);

    const state = reactive({
      tiposDocumentosDisponiveis: [] as number[],
      parcelasDisponiveis: [] as IOption[],
      detalhePagamento: {} as INotaFiscalDetalhePagamento,
      tipoDocumentoSelecionado: {} as IFormaPagamentoTipoDocumentoFinanceiro,
      qtdParcelasSelecionada: 0,
      valorParcelado: 0,
      valorRestante: 0,
      telaMobile: false,
    });

    const computedVenda = computed({
      get: () => props.venda,
      set: (val: IVenda) => {
        emit('update:venda', val);
      },
    });

    function preencherTiposDocumentosDisponiveis() {
      state.tiposDocumentosDisponiveis = [] as number[];
      props.venda.formaPagamento.tiposDocumentosFinanceiro.forEach((f) => {
        state.tiposDocumentosDisponiveis.push(f.codigoTipoDocumentoFinanceiro);
      });
    }

    function limparTela() {
      state.detalhePagamento = {} as INotaFiscalDetalhePagamento;
      state.detalhePagamento.centrosCustos = [] as INotaFiscalDetalhePagamentoCentroCusto[];
      state.detalhePagamento.anexos = [] as INotaFiscalDetalhePagamentoAnexo[];
      state.detalhePagamento.alterado = false;
      state.detalhePagamento.valorPagamento = 0;
      state.detalhePagamento.acrescimos = 0;
      state.detalhePagamento.outrasTaxas = 0;
      state.detalhePagamento.valorTotal = 0;
      state.tipoDocumentoSelecionado = {} as IFormaPagamentoTipoDocumentoFinanceiro;
      state.parcelasDisponiveis = [] as IOption[];
      state.qtdParcelasSelecionada = 0;
      state.valorParcelado = 0;
      state.valorRestante = 0;
      preencherTiposDocumentosDisponiveis();
    }

    function gerarNumeroTitulo() {
      let numero = '';
      let qtdParcela = 0;
      const sigla = state.tipoDocumentoSelecionado.siglaTipoDocumentoFinanceiro.substring(0, 3);
      props.venda.notaFiscal.detalhesPagamento.forEach((d) => {
        if (state.detalhePagamento.codigoTipoDocumentoFinanceiro === d.codigoTipoDocumentoFinanceiro) {
          qtdParcela += 1;
        }
      });
      numero = `V${props.venda.numero}${sigla}${qtdParcela + 1}`;
      return numero;
    }

    function preencherParcelasDisponiveis() {
      state.parcelasDisponiveis = [] as IOption[];
      state.tipoDocumentoSelecionado.parcelamentos.forEach((p, index) => {
        const item = {} as IOption;
        if (index === 0) {
          item.label = '1 Parcela';
        } else {
          item.label = `${index + 1} Parcelas`;
        }
        item.value = index + 1;
        state.parcelasDisponiveis.push(item);
      });
    }

    function alterarTipoDocumento() {
      if ((state.detalhePagamento.codigo !== undefined && state.detalhePagamento.codigo > 0) || state.detalhePagamento.alterado) {
        return;
      }

      if (state.detalhePagamento.codigoTipoDocumentoFinanceiro > 0 && state.detalhePagamento.codigoTipoDocumentoFinanceiro !== undefined) {
        const indexTipo = props.venda.formaPagamento.tiposDocumentosFinanceiro.findIndex((t) => t.codigoTipoDocumentoFinanceiro === state.detalhePagamento.codigoTipoDocumentoFinanceiro);
        state.tipoDocumentoSelecionado = props.venda.formaPagamento.tiposDocumentosFinanceiro[indexTipo];
        state.detalhePagamento.codigoPlanoContaCategoria = state.tipoDocumentoSelecionado.codigoPlanoContaCategoriaPadrao;
        state.detalhePagamento.nomePlanoContaCategoria = state.tipoDocumentoSelecionado.nomePlanoContaCategoriaPadrao;
        preencherParcelasDisponiveis();
        if (state.tipoDocumentoSelecionado.gerarCaixaDiario) {
          state.detalhePagamento.numeroTitulo = gerarNumeroTitulo();
          state.detalhePagamento.gerarCaixa = true;
          state.detalhePagamento.data = props.venda.dataVenda;
          state.detalhePagamento.valorPagamento = state.valorRestante;
          state.detalhePagamento.acrescimos = 0;
          state.detalhePagamento.outrasTaxas = 0;
        } else if (state.tipoDocumentoSelecionado.gerarCarteira) {
          state.valorParcelado = state.valorRestante;
          state.qtdParcelasSelecionada = 1;
        } else {
          state.detalhePagamento.valorPagamento = state.valorRestante;
        }
      }
    }

    function calcularValorRestante() {
      let totalLancado = 0;

      props.venda.notaFiscal.detalhesPagamento.forEach((d) => {
        totalLancado += d.valorTotal;
      });
      state.valorRestante = props.venda.notaFiscal.totalNotaFiscal - totalLancado;
      if (state.valorRestante <= 0) {
        apresentarMensagemAlerta('Não há mais valor para ser pago!');
        modalBase.computedVisivel = false;
      }
    }

    function gerarParcelas() {
      let totalAcrescimos = 0;
      let totalOutrasTaxas = 0;

      if (UtilitarioGeral.validaLista(state.tipoDocumentoSelecionado.parcelamentos)) {
        const parcelamento = state.tipoDocumentoSelecionado.parcelamentos[state.qtdParcelasSelecionada - 1];
        const parcelas = UtilitarioFinanceiro.calcularParcelas(state.valorParcelado, state.qtdParcelasSelecionada);

        for (let i = 0; i <= state.qtdParcelasSelecionada - 1; i += 1) {
          const parcela = {} as INotaFiscalDetalhePagamento;
          parcela.centrosCustos = [] as INotaFiscalDetalhePagamentoCentroCusto[];
          parcela.anexos = [] as INotaFiscalDetalhePagamentoAnexo[];
          parcela.numeroTitulo = gerarNumeroTitulo();
          parcela.parcela = i + 1;
          parcela.data = UtilitarioData.adicionarDiaNaData(props.venda.dataVenda, parcelamento.parcelas[i].prazo);
          parcela.valorPagamento = parcelas[i].valueOf();
          console.log('Valor Pagamento: ', i, ':', parcela.valorPagamento);
          // if (i < state.qtdParcelasSelecionada - 1) {
          //   parcela.valorPagamento = state.valorParcelado / state.qtdParcelasSelecionada;
          //    valorLancado += parcela.valorPagamento;
          //    console.log('Valor Total Parcela ', i, ':', parcela.valorPagamento);
          //  } else {
          //     parcela.valorPagamento = state.valorParcelado - valorLancado;
          //   }
          parcela.outrasTaxas = state.tipoDocumentoSelecionado.outrasTaxas;
          totalOutrasTaxas += parcela.outrasTaxas;
          if (parcelamento.taxa > 0) {
            parcela.acrescimos = UtilitarioMatematico.calcularValorPercentual(parcela.valorPagamento, parcelamento.taxa);
            totalAcrescimos += parcela.acrescimos;
          } else {
            parcela.acrescimos = 0;
            totalAcrescimos += parcela.acrescimos;
          }
          parcela.valorTotal = parcela.valorPagamento + parcela.acrescimos + parcela.outrasTaxas;
          parcela.codigoTipoDocumentoFinanceiro = state.tipoDocumentoSelecionado.codigoTipoDocumentoFinanceiro;
          parcela.descricaoTipoDocumentoFinanceiro = state.tipoDocumentoSelecionado.descricaoTipoDocumentoFinanceiro;
          parcela.codigoConta = state.tipoDocumentoSelecionado.codigoConta;
          parcela.descricaoConta = state.tipoDocumentoSelecionado.descricaoConta;
          parcela.codigoPlanoContaCategoria = state.tipoDocumentoSelecionado.codigoPlanoContaCategoriaPadrao;
          parcela.nomePlanoContaCategoria = state.tipoDocumentoSelecionado.nomePlanoContaCategoriaPadrao;
          parcela.gerarCarteira = true;
          parcela.alterado = true;
          computedVenda.value.notaFiscal.detalhesPagamento.push(parcela);
        }
      } else {
        const detalhePagamentoSemParcela = {} as INotaFiscalDetalhePagamento;
        detalhePagamentoSemParcela.centrosCustos = [] as INotaFiscalDetalhePagamentoCentroCusto[];
        detalhePagamentoSemParcela.anexos = [] as INotaFiscalDetalhePagamentoAnexo[];
        detalhePagamentoSemParcela.numeroTitulo = gerarNumeroTitulo();
        detalhePagamentoSemParcela.parcela = 1;
        detalhePagamentoSemParcela.data = props.venda.dataVenda;
        detalhePagamentoSemParcela.valorPagamento = state.valorParcelado;
        detalhePagamentoSemParcela.outrasTaxas = state.tipoDocumentoSelecionado.outrasTaxas;
        totalOutrasTaxas += detalhePagamentoSemParcela.outrasTaxas;
        detalhePagamentoSemParcela.valorTotal = state.valorParcelado + detalhePagamentoSemParcela.outrasTaxas;
        detalhePagamentoSemParcela.codigoTipoDocumentoFinanceiro = state.tipoDocumentoSelecionado.codigoTipoDocumentoFinanceiro;
        detalhePagamentoSemParcela.descricaoTipoDocumentoFinanceiro = state.tipoDocumentoSelecionado.descricaoTipoDocumentoFinanceiro;
        detalhePagamentoSemParcela.codigoConta = state.tipoDocumentoSelecionado.codigoConta;
        detalhePagamentoSemParcela.descricaoConta = state.tipoDocumentoSelecionado.descricaoConta;
        detalhePagamentoSemParcela.codigoPlanoContaCategoria = state.tipoDocumentoSelecionado.codigoPlanoContaCategoriaPadrao;
        detalhePagamentoSemParcela.nomePlanoContaCategoria = state.tipoDocumentoSelecionado.nomePlanoContaCategoriaPadrao;
        detalhePagamentoSemParcela.gerarCarteira = true;
        detalhePagamentoSemParcela.alterado = true;
        computedVenda.value.notaFiscal.detalhesPagamento.push(detalhePagamentoSemParcela);
      }

      emit('confirmar', totalAcrescimos, totalOutrasTaxas);
    }

    function validarCampos() {
      if (state.tipoDocumentoSelecionado.gerarCaixaDiario && !state.detalhePagamento.alterado) {
        if (state.detalhePagamento.valorPagamento === 0 || state.detalhePagamento.valorPagamento === undefined) {
          apresentarMensagemAlerta('O valor deve ser informado!');
          return false;
        }
      } else if (state.tipoDocumentoSelecionado.gerarCarteira && !state.detalhePagamento.alterado) {
        if (state.valorParcelado === 0 || state.valorParcelado === undefined) {
          apresentarMensagemAlerta('O valor parcelado deve ser informado!');
          return false;
        }

        if (state.qtdParcelasSelecionada === 0 || state.qtdParcelasSelecionada === undefined) {
          apresentarMensagemAlerta('A quantidade de parcelas deve ser informada!');
          return false;
        }

        if (state.tipoDocumentoSelecionado.valorMinimo > 0 && UtilitarioMatematico.arredondarValor(state.valorParcelado, 2) < UtilitarioMatematico.arredondarValor(state.tipoDocumentoSelecionado.valorMinimo, 2)) {
          apresentarMensagemAlerta(`O valor mínimo parcelado para esse tipo de documento é ${UtilitarioMascara.mascararValor(state.tipoDocumentoSelecionado.valorMinimo, 2)}!`);
          return false;
        }

        if (state.tipoDocumentoSelecionado.valorMaximo > 0 && UtilitarioMatematico.arredondarValor(state.valorParcelado, 2) > UtilitarioMatematico.arredondarValor(state.tipoDocumentoSelecionado.valorMaximo, 2)) {
          apresentarMensagemAlerta(`O valor máximo parcelado para esse tipo de documento é ${UtilitarioMascara.mascararValor(state.tipoDocumentoSelecionado.valorMaximo, 2)}!`);
          return false;
        }

        const valorParcela = state.valorParcelado / state.qtdParcelasSelecionada;
        if (state.tipoDocumentoSelecionado.valorMinParcela > 0 && UtilitarioMatematico.arredondarValor(valorParcela, 2) < UtilitarioMatematico.arredondarValor(state.tipoDocumentoSelecionado.valorMinParcela, 2)) {
          apresentarMensagemAlerta(`O valor mínimo da parcela para esse tipo de documento é ${UtilitarioMascara.mascararValor(state.tipoDocumentoSelecionado.valorMinParcela, 2)}!`);
          return false;
        }
      } else if ((!state.tipoDocumentoSelecionado.gerarCaixaDiario && !state.tipoDocumentoSelecionado.gerarCarteira) || state.detalhePagamento.alterado) {
        if (state.detalhePagamento.codigoTipoDocumentoFinanceiro === 0 || state.detalhePagamento.codigoTipoDocumentoFinanceiro === undefined) {
          apresentarMensagemAlerta('O Tipo de Documento deve ser informado!');
          return false;
        }

        if (state.detalhePagamento.codigoPlanoContaCategoria === 0 || state.detalhePagamento.codigoPlanoContaCategoria === undefined) {
          apresentarMensagemAlerta('A Categoria do Plano de Contas deve ser informada!');
          return false;
        }

        if (state.detalhePagamento.numeroTitulo === '' || state.detalhePagamento.numeroTitulo === undefined) {
          apresentarMensagemAlerta('O Número do Título deve ser informado!');
          return false;
        }

        if (state.detalhePagamento.data === '' || state.detalhePagamento.data === undefined) {
          apresentarMensagemAlerta('A Data deve ser informada!');
          return false;
        }

        if (state.detalhePagamento.valorPagamento === 0 || state.detalhePagamento.valorPagamento === undefined) {
          apresentarMensagemAlerta('O Valor deve ser maior que 0,00!');
          return false;
        }

        if (UtilitarioMatematico.arredondarValor(state.detalhePagamento.valorPagamento, 2) > UtilitarioMatematico.arredondarValor(state.valorRestante, 2) && !state.detalhePagamento.alterado) {
          apresentarMensagemAlerta(`O valor não pode ser maior que os R$${UtilitarioMascara.mascararValor(state.valorRestante, 2)} restantes!`);
          return false;
        }
      }

      return true;
    }

    function salvar() {
      state.detalhePagamento.valorTotal = state.detalhePagamento.valorPagamento + state.detalhePagamento.acrescimos + state.detalhePagamento.outrasTaxas;
      const retornoValidacao = validarCampos();
      if (!retornoValidacao) return;

      if (state.detalhePagamento.alterado) {
        const indexDetalhePagamento = computedVenda.value.notaFiscal.detalhesPagamento.findIndex((d) => d.numeroTitulo === state.detalhePagamento.numeroTitulo);
        computedVenda.value.notaFiscal.detalhesPagamento[indexDetalhePagamento] = state.detalhePagamento;
        emit('confirmar', 0, 0);
      } else if (state.tipoDocumentoSelecionado.gerarCarteira) {
        gerarParcelas();
      } else {
        state.detalhePagamento.alterado = true;
        computedVenda.value.notaFiscal.detalhesPagamento.push(state.detalhePagamento);
        emit('confirmar', 0, 0);
      }
      modalBase.computedVisivel = false;
    }

    function cancelar() {
      modalBase.computedVisivel = false;
    }

    watch(async () => modalBase.computedVisivel, async () => {
      state.telaMobile = UtilitarioDispositivo.larguraTelaMobile();
      limparTela();
      telaBase.carregando = true;
      if (modalBase.computedVisivel) {
        if ((props.detalhePagamento.codigo !== undefined && props.detalhePagamento.codigo > 0) || props.detalhePagamento.alterado) {
          state.detalhePagamento = UtilitarioGeral.clonarObjeto(props.detalhePagamento);
          const indexTipo = props.venda.formaPagamento.tiposDocumentosFinanceiro.findIndex((t) => t.codigoTipoDocumentoFinanceiro === state.detalhePagamento.codigoTipoDocumentoFinanceiro);
          state.tipoDocumentoSelecionado = props.venda.formaPagamento.tiposDocumentosFinanceiro[indexTipo];
          const anexos = await servicoNotaFiscal.obterAnexosDetalhePagamento(state.detalhePagamento.codigo);
          if (anexos !== null && anexos !== undefined) {
            state.detalhePagamento.anexos = anexos;
          }
        } else {
          calcularValorRestante();
        }
      }
      telaBase.carregando = false;
    });

    return {
      props,
      state,
      telaBase,
      modalBase,
      computedVenda,
      UtilitarioGeral,
      alterarTipoDocumento,
      salvar,
      cancelar,
    };
  },
});
