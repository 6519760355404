
import {
  computed, defineComponent, reactive, ref,
} from 'vue';
import { IOption } from '@/core/models/AntDesign/IOption';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import { useTelaBase } from '@/core/composables/TelaBase';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import ServicoCfop from '@/servicos/Cadastros/Tributacoes/ServicoCfop';

export default defineComponent({
  name: 'SelecionarCfop',
  props: {
    varios: {
      type: Boolean,
      default: false,
    },
    codigoSelecionado: {
      type: Number || undefined,
    },
    codigosSelecionados: {
      type: Array as () => number[],
      default: () => [],
    },
    descricaoSelecionado: {
      type: String,
      default: '',
    },
    cfopSelecionado: {
      type: Number,
      default: 0,
    },
    placeholder: {
      type: String,
      default: 'Selecione',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    classCss: {
      type: String,
      default: '',
    },
    ignorarCfops: {
      type: Array as () => number[],
    },
    filtrarApenasCfopsEntradas: {
      type: Boolean,
      default: false,
    },
    filtrarApenasCfopsSaidas: {
      type: Boolean,
      default: false,
    },
    tipoOperacao: {
      type: Number,
      default: 0,
    },
    retornarDescricaoSemCodigoCfop: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:codigoSelecionado', 'update:codigosSelecionados', 'update:descricaoSelecionado', 'update:cfopSelecionado', 'change'],
  setup(props, { emit }) {
    const { montaOpcoesComListaConsultaRapida } = useTelaBase();
    const refSelecionarCfop = ref<HTMLElement | null>(null);

    let debounce = 0;
    const servicoCfop = new ServicoCfop();

    const state = reactive({
      listaCfops: [] as IOption[],
      buscandoDados: false,
      emDigitacao: false,
    });

    async function obterCfopPorCodigo(valor: any) {
      if (state.emDigitacao) { return; }
      if (!UtilitarioGeral.valorValido(valor)) { return; }
      if (!(valor > 0)) { return; }

      if (props.varios) {
        if (props.codigosSelecionados.length === 0) { return; }
      } else if (props.codigoSelecionado === 0) {
        return;
      }
      let efetuarConsulta = true;

      if (UtilitarioGeral.validaLista(state.listaCfops)) {
        if (props.varios) {
          const listaCodigosConsulta = state.listaCfops.map((c) => c.value);
          const diferencas = props.codigosSelecionados.filter((c) => !listaCodigosConsulta.includes(c));
          if (!UtilitarioGeral.validaLista(diferencas)) {
            efetuarConsulta = false;
          }
        } else {
          const existeLista = state.listaCfops.find((c) => c.value === props.codigoSelecionado);
          if (existeLista !== undefined) {
            efetuarConsulta = false;
          }
        }
      }

      if (!efetuarConsulta) return;

      state.listaCfops = [];

      const parametrosConsultaRapida: IParametrosConsultaRapida = {
        apenasAtivos: false, recursoAssociado: '',
      };

      if (props.varios) {
        parametrosConsultaRapida.filtrarPorVariosCodigos = true;
        parametrosConsultaRapida.valores = valor;
      } else {
        parametrosConsultaRapida.filtrarPorCodigo = true;
        parametrosConsultaRapida.valor = valor;
      }

      const listaCfops = await servicoCfop.consultaRapida(parametrosConsultaRapida, props.filtrarApenasCfopsEntradas, props.filtrarApenasCfopsSaidas, props.tipoOperacao);
      state.listaCfops = montaOpcoesComListaConsultaRapida(listaCfops);
      if (!UtilitarioGeral.validaLista(listaCfops)) {
        if (props.varios) {
          emit('update:codigosSelecionados', undefined);
        } else {
          emit('update:codigoSelecionado', undefined);
        }
      }
    }

    async function pesquisarCfop(valorPesquisa: any) {
      state.emDigitacao = true;

      state.buscandoDados = true;
      state.listaCfops = [];
      clearTimeout(debounce);
      debounce = setTimeout(async () => {
        const parametrosConsultaRapida: IParametrosConsultaRapida = {
          valor: valorPesquisa, apenasAtivos: true, recursoAssociado: '', quantidadeRegistros: 100,
        };
        let listaCfops = await servicoCfop.consultaRapida(parametrosConsultaRapida, props.filtrarApenasCfopsEntradas, props.filtrarApenasCfopsSaidas, props.tipoOperacao);
        if (props.ignorarCfops !== undefined) {
          props.ignorarCfops.forEach((codigoCfop) => {
            listaCfops = listaCfops.filter((c) => c.codigo !== codigoCfop);
          });
        }
        state.buscandoDados = false;
        state.listaCfops = montaOpcoesComListaConsultaRapida(listaCfops);
      }, 600);
    }

    function obterCfop():any {
      obterCfopPorCodigo(props.codigoSelecionado);
      return (props.codigoSelecionado === 0 ? undefined : props.codigoSelecionado);
    }

    const computedDescricaoSelecionado = computed({
      get: () => props.descricaoSelecionado,
      set: (val: string) => {
        emit('update:descricaoSelecionado', val);
      },
    });

    const computedCfopSelecionado = computed({
      get: () => props.cfopSelecionado,
      set: (val: number) => {
        emit('update:cfopSelecionado', val);
      },
    });

    const computedCodigoSelecionado = computed({
      get: () => obterCfop(),
      set: (val: number) => {
        emit('update:codigoSelecionado', val);
        let descricaoCfop = '';
        const option = state.listaCfops.find((c) => c.value === val);
        if (option?.label !== undefined && option?.label !== '') {
          if (option !== undefined) {
            computedCfopSelecionado.value = Number(option.label.substring(0, 4));
            descricaoCfop = option.label;
            if (props.retornarDescricaoSemCodigoCfop) {
              descricaoCfop = descricaoCfop.slice(-6);
            }
          }
          computedDescricaoSelecionado.value = descricaoCfop;
        }
      },
    });

    function obterCfops():number[] {
      obterCfopPorCodigo(props.codigosSelecionados);
      return props.codigosSelecionados;
    }

    const computedCodigosSelecionados = computed({
      get: () => obterCfops(),
      set: (val: number[]) => {
        emit('update:codigosSelecionados', val);
      },
    });

    function change() {
      emit('change', props.varios ? props.codigosSelecionados : props.codigoSelecionado);
    }

    function focus() {
      if (refSelecionarCfop.value) {
        refSelecionarCfop.value.focus();
      }
    }

    return {
      props,
      state,
      refSelecionarCfop,
      pesquisarCfop,
      computedCodigoSelecionado,
      computedCodigosSelecionados,
      change,
      focus,
    };
  },
});
