
import {
  computed, defineComponent, onBeforeMount, reactive,
} from 'vue';
import { INotaFiscalDetalhePagamento } from '@/models/Entidades/Fiscal/INotaFiscal';
import UtilitarioDispositivo from '@/core/utilitarios/UtilitarioDispositivo';
import SelecionarTipoPagamentoDevolucao from '@/components/SelecionarTipoDevolucaoPagamento.vue';
import DevolucaoVendaPagamentoTitulosPagar from './DevolucaoVendaPagamentoTitulosPagar.vue';
import DevolucaoVendaPagamentoCredito from './DevolucaoVendaPagamentoCredito.vue';
import { ETipoMovimentoComercial } from '@/core/models/Enumeradores/ETipoMovimentoComercial';

export default defineComponent({
  name: 'DevolucaoVendaPagamento',
  props: {
    empresa: {
      type: Number,
      required: true,
    },
    tipoDevolucaoPagamento: {
      type: Number,
      required: true,
    },
    totalDetalhesPagamento: {
      type: Number,
      required: true,
    },
    detalhesPagamento: {
      type: Array as () => INotaFiscalDetalhePagamento[],
      required: true,
    },
    detalheCredito: {
      type: Object as () => INotaFiscalDetalhePagamento,
      required: true,
    },
    editavel: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    SelecionarTipoPagamentoDevolucao,
    DevolucaoVendaPagamentoTitulosPagar,
    DevolucaoVendaPagamentoCredito,
  },
  emits: ['update:tipoDevolucaoPagamento', 'update:detalhesPagamento', 'update:detalheCredito', 'alterarTipoDevolucao', 'atualizarDetalhePagamento'],
  setup(props, { emit }) {
    const state = reactive({
      detalheCredito: {} as INotaFiscalDetalhePagamento,
      telaMobile: false,
      tipoAnterior: 0,
    });

    const computedTipoDevolucaoPagamento = computed({
      get: () => props.tipoDevolucaoPagamento,
      set: (val: number) => {
        state.tipoAnterior = props.tipoDevolucaoPagamento;
        emit('update:tipoDevolucaoPagamento', val);
      },
    });

    const computedDetalhesPagamento = computed({
      get: () => props.detalhesPagamento,
      set: (val: INotaFiscalDetalhePagamento[]) => {
        emit('update:detalhesPagamento', val);
      },
    });

    const computedDetalheCredito = computed({
      get: () => props.detalheCredito,
      set: (val: INotaFiscalDetalhePagamento) => {
        emit('update:detalheCredito', val);
      },
    });

    function alterarTipoDevolucao() {
      emit('alterarTipoDevolucao');
    }

    function atualizarDetalhePagamento(indexSelecionado: number) {
      emit('atualizarDetalhePagamento', indexSelecionado);
    }

    onBeforeMount(async () => {
      state.telaMobile = UtilitarioDispositivo.larguraTelaMobile();
    });

    return {
      props,
      state,
      computedTipoDevolucaoPagamento,
      computedDetalhesPagamento,
      computedDetalheCredito,
      ETipoMovimentoComercial,
      alterarTipoDevolucao,
      atualizarDetalhePagamento,
    };
  },
});
