
import {
  computed, defineComponent, reactive,
} from 'vue';
import { ETipoDevolucaoPagamento } from '@/models/Enumeradores/ETipoDevolucaoPagamento';
import { ETipoMovimentoComercial } from '@/core/models/Enumeradores/ETipoMovimentoComercial';

export default defineComponent({
  name: 'SelecionarTipoDevolucaoPagamento',
  components: {
  },
  props: {
    valor: {
      type: Number,
      default: 1,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    radioButton: {
      type: Boolean,
      default: false,
    },
    tipoMovimentacao: {
      type: Number,
      required: true,
    },
  },
  emits: ['update:valor', 'change'],
  setup(props, { emit }) {
    const state = reactive({
      dica: '',
    });

    const computedValor = computed({
      get: () => props.valor,
      set: (valor: number) => {
        emit('update:valor', valor);
      },
    });

    function obterDica(valor: ETipoDevolucaoPagamento) {
      switch (valor) {
        case ETipoDevolucaoPagamento.Manter:
          state.dica = 'O lançamento financeiro lançado no movimento original será mantido.';
          break;
        case ETipoDevolucaoPagamento.LancarCredito:
          if (props.tipoMovimentacao === ETipoMovimentoComercial.DevolucaoCompras) {
            state.dica = 'Será lançado um crédito para a empresa com o fornecedor';
          } else {
            state.dica = 'Será lançado um crédito para o cliente';
          }
          break;
        case ETipoDevolucaoPagamento.AbaterSaldo:
          if (props.tipoMovimentacao === ETipoMovimentoComercial.DevolucaoCompras) {
            state.dica = 'O valor devolvido será abatido no saldo das contas a pagar lançadas na movimentação original.';
          } else {
            state.dica = 'O valor devolvido será abatido no saldo das contas a receber lançadas na movimentação original.';
          }
          break;
        case ETipoDevolucaoPagamento.LancarMovimentoFinanceiro:
          if (props.tipoMovimentacao === ETipoMovimentoComercial.DevolucaoCompras) {
            state.dica = 'Lançar contas a receber para o fornecedor.';
          } else {
            state.dica = 'Lançar contas a pagar para o cliente.';
          }
          break;
        default:
          state.dica = '';
          break;
      }
    }

    function defineValorSelecionado(valor:number) {
      emit('update:valor', valor);
      emit('change', valor);
      obterDica(valor);
    }

    function defineValorMarcado(valor:number) {
      emit('change', valor);
    }

    return {
      props,
      state,
      computedValor,
      ETipoMovimentoComercial,
      obterDica,
      defineValorSelecionado,
      defineValorMarcado,
    };
  },
});
