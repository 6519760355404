import ApiERP from '@/core/conectores/ApiERP';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { IListaPaginada } from '@/core/models/Consulta/IListaPaginada';
import { IPropriedadeConsulta } from '@/core/models/Consulta/PropriedadeConsulta';
import { IParametrosConsulta } from '@/core/models/Consulta/IParametrosConsulta';
import storeSistema from '@/store/storeSistema';
import { IRequisicaoAutorizacao, IRequisicaoAutorizacaoDestinatario } from '@/models/Entidades/MeuSistema/IRequisicaoAutorizacao';
import { EStatusRequisicaoAutorizacao } from '@/models/Enumeradores/MeuSistema/EStatusRequisicaoAutorizacao';

/**
 * Serviço de Controle de Autorizações
 * Fornece todas regras de negócios e lógicas relacionado a autorizações.
 */
class ServicoControleAutorizacoes {
    endPoint = 'controle-autorizacoes';

    apiERP = new ApiERP();

    public requisicaoSistema():void {
      this.apiERP = new ApiERP(true);
    }

    public async obterPropriedadesConsultaRequisicoes(): Promise<IPropriedadeConsulta[]> {
      const listaPropriedades = await this.apiERP.obterPropriedadesConsulta(`${this.endPoint}/requisicoes`);
      return listaPropriedades;
    }

    public async buscarRequisicoes(parametrosConsulta: IParametrosConsulta, filtros?: IFiltroGenerico[]): Promise<IListaPaginada> {
      let parametrosAdicionais = '';
      parametrosAdicionais = this.apiERP.obterParametroEmpresas(parametrosConsulta.empresas);

      if (filtros !== undefined) {
        const jsonFiltros = JSON.stringify(filtros);
        parametrosAdicionais += `&Filtros=${jsonFiltros}`;
      }
      if (parametrosConsulta.numeroPagina !== undefined && parametrosConsulta.numeroPagina > 0) {
        parametrosAdicionais += `&NumeroPagina=${parametrosConsulta.numeroPagina}`;
      }
      if (parametrosConsulta.qtdeRegistrosPagina !== undefined && parametrosConsulta.qtdeRegistrosPagina > 0) {
        parametrosAdicionais += `&QtdeRegistrosPagina=${parametrosConsulta.qtdeRegistrosPagina}`;
      }
      if (parametrosConsulta.qtdeRegistrosTotal !== undefined && parametrosConsulta.qtdeRegistrosTotal > 0) {
        parametrosAdicionais += `&QtdeRegistrosTotal=${parametrosConsulta.qtdeRegistrosTotal}`;
      }
      if (parametrosConsulta.ordenacao !== undefined) {
        parametrosConsulta.ordenacao.forEach((item) => {
          parametrosAdicionais += `&Ordenacao=${item}`;
        });
      }
      if (parametrosConsulta.codigosSelecionados !== undefined) {
        parametrosConsulta.codigosSelecionados.forEach((codigo) => {
          parametrosAdicionais += `&CodigosSelecionados=${codigo}`;
        });
      }

      const result: any = await this.apiERP.get(`${this.endPoint}/requisicoes${parametrosAdicionais}`);
      const listaPaginada = {} as IListaPaginada;
      listaPaginada.metaData = JSON.parse(result.headers['x-paginacao']);
      listaPaginada.dados = result.data;
      return listaPaginada;
    }

    public async obterRequisicao(codigo: number): Promise<IRequisicaoAutorizacao> {
      const result: any = await this.apiERP.get(`${this.endPoint}/requisicoes/${codigo}`);
      return result.data;
    }

    public async obterDestinatarios(codigo: number): Promise<IRequisicaoAutorizacaoDestinatario[]> {
      const result: any = await this.apiERP.get(`${this.endPoint}/requisicoes/destinatarios/${codigo}`);
      return result.data;
    }

    public async criarRequisicao(permissao: string, descricao: string): Promise<IRetornoRequisicao> {
      const requisicao = {} as IRequisicaoAutorizacao;
      requisicao.codigoEmpresa = storeSistema.getters.codigoEmpresaOperacao();
      requisicao.codigoUsuarioRequerente = storeSistema.getters.codigoUsuarioAutenticado();
      requisicao.identificadorPermissao = permissao;
      requisicao.motivoRequisicao = descricao;
      const result: any = await this.apiERP.post(`${this.endPoint}/requisicoes`, requisicao);
      return result.data;
    }

    public async responderRequisicoes(codigos: number[], status: EStatusRequisicaoAutorizacao, descricao: string): Promise<IRetornoRequisicao> {
      let codigosRequisicao = '';
      if (codigos !== undefined && codigos.length > 0) {
        codigos.forEach((codigo) => {
          codigosRequisicao += (codigosRequisicao !== '' ? '&' : '?');
          codigosRequisicao += `Codigos=${codigo}`;
        });
      }
      const result: any = await this.apiERP.put(`${this.endPoint}/requisicoes/status/${status}${codigosRequisicao}`, descricao);
      return result.data;
    }

    public async validarPermissao(codigo: number): Promise<IRetornoRequisicao> {
      const result: any = await this.apiERP.get(`${this.endPoint}/requisicoes/validar-permissao/${codigo}`);
      return result.data;
    }

    public obterDescricaoStatusRequisicao(status: EStatusRequisicaoAutorizacao): string {
      switch (status) {
        case EStatusRequisicaoAutorizacao.Pendente:
          return 'Pendente';
        case EStatusRequisicaoAutorizacao.Autorizada:
          return 'Autorizada';
        case EStatusRequisicaoAutorizacao.Negada:
          return 'Negada';
        default:
          return 'Expirada';
      }
    }
}
export default ServicoControleAutorizacoes;
