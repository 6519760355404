import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_select, {
      value: _ctx.computedValor,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedValor) = $event)),
      onChange: _ctx.defineValorSelecionado,
      class: _normalizeClass('ss-tamanho-completo ' + _ctx.props.classCss),
      onBlur: _ctx.blur,
      disabled: _ctx.props.disabled,
      placeholder: _ctx.props.placeholder,
      "show-search": true
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_select_option, { value: '01' }, {
          default: _withCtx(() => [
            _createTextVNode("01 - Nota Fiscal")
          ]),
          _: 1
        }),
        _createVNode(_component_a_select_option, { value: '04' }, {
          default: _withCtx(() => [
            _createTextVNode("04 - Nota Fiscal de Produtor")
          ]),
          _: 1
        }),
        _createVNode(_component_a_select_option, { value: '1B' }, {
          default: _withCtx(() => [
            _createTextVNode("1B - Nota Fiscal Avulsa")
          ]),
          _: 1
        }),
        _createVNode(_component_a_select_option, { value: '21' }, {
          default: _withCtx(() => [
            _createTextVNode("21 - Nota Fiscal de Serviço de Comunicação")
          ]),
          _: 1
        }),
        _createVNode(_component_a_select_option, { value: '22' }, {
          default: _withCtx(() => [
            _createTextVNode("22 - Serviço de Telecomunicação")
          ]),
          _: 1
        }),
        _createVNode(_component_a_select_option, { value: '28' }, {
          default: _withCtx(() => [
            _createTextVNode("28 - Consumo Fornecimento de Gás")
          ]),
          _: 1
        }),
        _createVNode(_component_a_select_option, { value: '29' }, {
          default: _withCtx(() => [
            _createTextVNode("29 - Conta de fornecimento de água canalizada")
          ]),
          _: 1
        }),
        _createVNode(_component_a_select_option, { value: '55' }, {
          default: _withCtx(() => [
            _createTextVNode("55 - NF-e")
          ]),
          _: 1
        }),
        _createVNode(_component_a_select_option, { value: '65' }, {
          default: _withCtx(() => [
            _createTextVNode("65 - NFC-e")
          ]),
          _: 1
        }),
        _createVNode(_component_a_select_option, { value: '66' }, {
          default: _withCtx(() => [
            _createTextVNode("66 - NF-e Energia Elétrica")
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["value", "onChange", "class", "onBlur", "disabled", "placeholder"])
  ]))
}