import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createSlots as _createSlots } from "vue"

const _hoisted_1 = ["title"]
const _hoisted_2 = { style: {"font-weight":"600","padding":"5px"} }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.props.layoutCheckBox)
      ? (_openBlock(), _createBlock(_component_a_spin, {
          key: 0,
          spinning: (_ctx.state.tabelasPrecos.length === 0)
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.tabelasPrecos, (tabelaPreco) => {
              return (_openBlock(), _createElementBlock("div", {
                key: tabelaPreco.codigo,
                style: {"min-width":"300px","margin":"6px 0","border":"solid 1px #f5f8f9","border-radius":"4px","width":"100%"}
              }, [
                _createElementVNode("div", {
                  title: tabelaPreco.descricao,
                  style: {"background-color":"#f5f8f9","font-weight":"600","width":"100%"}
                }, [
                  _createElementVNode("span", _hoisted_2, _toDisplayString(tabelaPreco.descricao), 1)
                ], 8, _hoisted_1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tabelaPreco.itens, (itemTabela) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: itemTabela.codigo,
                    style: {"width":"100%","padding":"0px 4px 2px 4px"},
                    class: "ant-row"
                  }, [
                    _createElementVNode("div", {
                      class: "ant-col ant-col-xs-12 ant-col-xl-18",
                      onClick: ($event: any) => (_ctx.definePrecoItemTabelaSelecionado(itemTabela))
                    }, [
                      _createVNode(_component_a_checkbox, {
                        checked: (_ctx.computedCodigoItemSelecionado === itemTabela.codigo)
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(itemTabela.descricaoTipoPreco), 1)
                        ]),
                        _: 2
                      }, 1032, ["checked"])
                    ], 8, _hoisted_3),
                    _createElementVNode("div", {
                      class: "ant-col ant-col-xs-12 ant-col-xl-6",
                      onClick: ($event: any) => (_ctx.definePrecoItemTabelaSelecionado(itemTabela))
                    }, " R$ " + _toDisplayString(_ctx.UtilitarioMascara.mascararValor(itemTabela.valor,itemTabela.casasDecimaisTipoPreco)), 9, _hoisted_4)
                  ]))
                }), 128))
              ]))
            }), 128))
          ]),
          _: 1
        }, 8, ["spinning"]))
      : (_openBlock(), _createBlock(_component_a_select, {
          key: 1,
          ref: "refSelecionarTabelaPreco",
          class: _normalizeClass('ss-tamanho-completo ' + _ctx.props.classCss),
          title: _ctx.props.title,
          value: _ctx.computedCodigoItemSelecionado,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedCodigoItemSelecionado) = $event)),
          placeholder: _ctx.props.placeholder,
          optionFilterProp: "label",
          "not-found-content": _ctx.state.buscandoDados ? undefined : null,
          disabled: _ctx.props.disabled,
          onChange: _ctx.change,
          onBlur: _ctx.blur
        }, _createSlots({
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.itens, (item) => {
              return (_openBlock(), _createBlock(_component_a_select_option, {
                key: item.codigo,
                value: item.codigo
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.obterDescricaoItem(item)), 1)
                ]),
                _: 2
              }, 1032, ["value"]))
            }), 128))
          ]),
          _: 2
        }, [
          (_ctx.state.buscandoDados)
            ? {
                name: "notFoundContent",
                fn: _withCtx(() => [
                  _createTextVNode("Buscando Itens"),
                  _createVNode(_component_a_spin, { size: "small" })
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["class", "title", "value", "placeholder", "not-found-content", "disabled", "onChange", "onBlur"]))
  ]))
}