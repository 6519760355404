
import {
  computed, defineComponent, reactive,
} from 'vue';
import { INotaFiscal } from '@/models/Entidades/Fiscal/INotaFiscal';
import EditorHtml from '@/core/components/Tela/EditorHtml.vue';

export default defineComponent({
  name: 'NotaFiscalDadosAdicionais',
  props: {
    notaFiscal: {
      type: Object as () => INotaFiscal,
      required: true,
    },
    editavel: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    EditorHtml,
  },
  emits: ['update:notaFiscal', 'campoAlterado'],
  setup(props, { emit }) {
    const state = reactive({});

    const computedNotaFiscal = computed({
      get: () => props.notaFiscal,
      set: (val: INotaFiscal) => {
        emit('update:notaFiscal', val);
      },
    });

    function campoAlterado(campo: string) {
      emit('campoAlterado', campo);
    }

    return {
      props,
      state,
      computedNotaFiscal,
      campoAlterado,
    };
  },
});
