import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ant-row" }
const _hoisted_2 = { class: "ant-col ant-col-xs-24 ant-col-xl-24" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = { class: "ant-row" }
const _hoisted_5 = { class: "ant-col ant-col-xs-24 ant-col-xl-24" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_editor_html = _resolveComponent("editor-html")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_form, { layout: "vertical" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_a_form_item, {
              label: "Dados adicionais da Nota Fiscal",
              class: "ss-margin-campos"
            }, {
              default: _withCtx(() => [
                _withDirectives(_createElementVNode("textarea", {
                  class: "ant-input",
                  rows: "4",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedNotaFiscal.dadosAdicionais) = $event)),
                  onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.campoAlterado('DadosAdicionais'))),
                  disabled: !_ctx.props.editavel
                }, null, 40, _hoisted_3), [
                  [_vModelText, _ctx.computedNotaFiscal.dadosAdicionais]
                ])
              ]),
              _: 1
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_a_form_item, {
              label: "Observações internas",
              class: "ss-margin-campos"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_editor_html, {
                  value: _ctx.computedNotaFiscal.observacoesInternas,
                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.computedNotaFiscal.observacoesInternas) = $event)),
                  onBlur: _cache[3] || (_cache[3] = ($event: any) => (_ctx.campoAlterado('ObservacoesInternas'))),
                  disabled: !_ctx.props.editavel
                }, null, 8, ["value", "disabled"])
              ]),
              _: 1
            })
          ])
        ])
      ]),
      _: 1
    })
  ]))
}