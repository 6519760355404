import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ant-row" }
const _hoisted_2 = { class: "ant-col ant-col-xs-24 ant-col-xl-24" }
const _hoisted_3 = { class: "ant-row" }
const _hoisted_4 = { class: "ant-col ant-col-xs-12 ant-col-xl-4" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = { class: "ant-col ant-col-xs-12 ant-col-xl-4" }
const _hoisted_7 = { class: "ant-col ant-col-xs-16 ant-col-xl-4" }
const _hoisted_8 = { class: "ant-col ant-col-xs-16 ant-col-xl-4" }
const _hoisted_9 = { class: "ant-col ant-col-xs-16 ant-col-xl-4" }
const _hoisted_10 = { class: "ant-col ant-col-xs-16 ant-col-xl-4" }
const _hoisted_11 = { class: "ant-row" }
const _hoisted_12 = { class: "ant-col ant-col-xs-24 ant-col-xl-24" }
const _hoisted_13 = ["disabled"]
const _hoisted_14 = { class: "ant-row" }
const _hoisted_15 = { class: "ant-col ant-col-xs-24 ant-col-xl-24" }
const _hoisted_16 = { class: "ant-row" }
const _hoisted_17 = {
  class: "ant-col",
  style: {"flex":"1 1 auto"}
}
const _hoisted_18 = { class: "ant-row" }
const _hoisted_19 = { class: "ant-col ant-col-xs-24 ant-col-xl-24" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_selecionar_data = _resolveComponent("selecionar-data")!
  const _component_campo_numerico = _resolveComponent("campo-numerico")!
  const _component_selecionar_conta = _resolveComponent("selecionar-conta")!
  const _component_selecionar_categoria_plano_conta = _resolveComponent("selecionar-categoria-plano-conta")!
  const _component_card = _resolveComponent("card")!
  const _component_nota_fiscal_detalhe_pagamento_centro_custo = _resolveComponent("nota-fiscal-detalhe-pagamento-centro-custo")!
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_nota_fiscal_detalhe_pagamento_anexo = _resolveComponent("nota-fiscal-detalhe-pagamento-anexo")!
  const _component_a_tabs = _resolveComponent("a-tabs")!
  const _component_a_collapse_panel = _resolveComponent("a-collapse-panel")!
  const _component_a_collapse = _resolveComponent("a-collapse")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_card, { titulo: "DADOS DO LANÇAMENTO" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_a_form_item, {
                  label: "N.Identificação",
                  class: "ss-margin-campos"
                }, {
                  default: _withCtx(() => [
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      class: "ant-input",
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedDetalhePagamento.numeroTitulo) = $event)),
                      disabled: _ctx.computedDetalhePagamento.alterado || _ctx.UtilitarioGeral.validaCodigo(_ctx.computedDetalhePagamento.codigo)
                    }, null, 8, _hoisted_5), [
                      [_vModelText, _ctx.computedDetalhePagamento.numeroTitulo]
                    ])
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_a_form_item, {
                  label: "Vencimento",
                  class: "ss-margin-campos"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_selecionar_data, {
                      dataSelecionada: _ctx.computedDetalhePagamento.data,
                      "onUpdate:dataSelecionada": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.computedDetalhePagamento.data) = $event)),
                      disabled: !_ctx.props.editavel
                    }, null, 8, ["dataSelecionada", "disabled"])
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_a_form_item, {
                  label: "Valor",
                  class: "ss-margin-campos"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_campo_numerico, {
                      monetario: true,
                      quantidadeCasasDecimais: 2,
                      valor: _ctx.computedDetalhePagamento.valorPagamento,
                      "onUpdate:valor": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.computedDetalhePagamento.valorPagamento) = $event)),
                      disabled: !_ctx.props.editavel
                    }, null, 8, ["valor", "disabled"])
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_a_form_item, {
                  label: "Acréscimos",
                  class: "ss-margin-campos"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_campo_numerico, {
                      monetario: true,
                      quantidadeCasasDecimais: 2,
                      valor: _ctx.computedDetalhePagamento.acrescimos,
                      "onUpdate:valor": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.computedDetalhePagamento.acrescimos) = $event)),
                      disabled: !_ctx.props.editavel
                    }, null, 8, ["valor", "disabled"])
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_a_form_item, {
                  label: "Outras Taxas",
                  class: "ss-margin-campos"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_campo_numerico, {
                      monetario: true,
                      quantidadeCasasDecimais: 2,
                      valor: _ctx.computedDetalhePagamento.outrasTaxas,
                      "onUpdate:valor": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.computedDetalhePagamento.outrasTaxas) = $event)),
                      disabled: !_ctx.props.editavel
                    }, null, 8, ["valor", "disabled"])
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_a_form_item, {
                  label: "Total",
                  class: "ss-margin-campos"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_campo_numerico, {
                      monetario: true,
                      quantidadeCasasDecimais: 2,
                      valor: _ctx.computedDetalhePagamento.valorTotal,
                      "onUpdate:valor": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.computedDetalhePagamento.valorTotal) = $event)),
                      disabled: _ctx.computedDetalhePagamento.alterado || _ctx.UtilitarioGeral.validaCodigo(_ctx.computedDetalhePagamento.codigo),
                      "@update": _ctx.atualizarValorTotal()
                    }, null, 8, ["valor", "disabled", "@update"])
                  ]),
                  _: 1
                })
              ])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_component_a_form_item, {
                  label: "Observações/Descrição",
                  class: "ss-margin-campos"
                }, {
                  default: _withCtx(() => [
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      class: "ant-input",
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.computedDetalhePagamento.observacoes) = $event)),
                      disabled: !_ctx.props.editavel
                    }, null, 8, _hoisted_13), [
                      [_vModelText, _ctx.computedDetalhePagamento.observacoes]
                    ])
                  ]),
                  _: 1
                })
              ])
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("div", _hoisted_15, [
                _createVNode(_component_selecionar_conta, {
                  empresas: [_ctx.props.empresa],
                  "codigo-selecionado": _ctx.computedDetalhePagamento.codigoConta,
                  "onUpdate:codigoSelecionado": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.computedDetalhePagamento.codigoConta) = $event)),
                  "descricao-selecionado": _ctx.computedDetalhePagamento.descricaoConta,
                  "onUpdate:descricaoSelecionado": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.computedDetalhePagamento.descricaoConta) = $event)),
                  disabled: !_ctx.props.editavel
                }, null, 8, ["empresas", "codigo-selecionado", "descricao-selecionado", "disabled"])
              ])
            ]),
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("div", _hoisted_17, [
                _createVNode(_component_a_form_item, {
                  label: "Categoria do Plano de Contas",
                  class: "ss-margin-campos"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_selecionar_categoria_plano_conta, {
                      empresas: [_ctx.props.empresa],
                      tipoPlanoConta: 1,
                      codigoSelecionado: _ctx.computedDetalhePagamento.codigoPlanoContaCategoria,
                      "onUpdate:codigoSelecionado": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.computedDetalhePagamento.codigoPlanoContaCategoria) = $event)),
                      descricaoSelecionado: _ctx.computedDetalhePagamento.nomePlanoContaCategoria,
                      "onUpdate:descricaoSelecionado": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.computedDetalhePagamento.nomePlanoContaCategoria) = $event)),
                      disabled: !_ctx.props.editavel
                    }, null, 8, ["empresas", "codigoSelecionado", "descricaoSelecionado", "disabled"])
                  ]),
                  _: 1
                })
              ])
            ])
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_18, [
      _createElementVNode("div", _hoisted_19, [
        _createVNode(_component_a_collapse, {
          ghost: "",
          class: "ss-accordion"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_collapse_panel, {
              class: "ss-accordion",
              header: "DADOS ADICIONAIS"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_tabs, { defaultActiveKey: "1" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_tab_pane, {
                      key: "1",
                      tab: "CENTROS DE CUSTO"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_nota_fiscal_detalhe_pagamento_centro_custo, {
                          visivel: true,
                          valor: _ctx.computedDetalhePagamento.valorTotal,
                          codigoRegistro: _ctx.computedDetalhePagamento.codigo,
                          centrosCusto: _ctx.computedDetalhePagamento.centrosCustos,
                          "onUpdate:centrosCusto": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.computedDetalhePagamento.centrosCustos) = $event)),
                          editavel: _ctx.props.editavel
                        }, null, 8, ["valor", "codigoRegistro", "centrosCusto", "editavel"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_tab_pane, {
                      key: "2",
                      tab: "ANEXOS"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_nota_fiscal_detalhe_pagamento_anexo, {
                          visivel: true,
                          codigoRegistro: _ctx.computedDetalhePagamento.codigo,
                          empresa: _ctx.props.empresa,
                          anexos: _ctx.computedDetalhePagamento.anexos,
                          "onUpdate:anexos": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.computedDetalhePagamento.anexos) = $event)),
                          editavel: _ctx.props.editavel
                        }, null, 8, ["codigoRegistro", "empresa", "anexos", "editavel"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ])
  ], 64))
}