import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ant-row" }
const _hoisted_2 = { class: "ant-col ant-col-xs-12 ant-col-xl-5" }
const _hoisted_3 = { class: "ant-col ant-col-xs-12 ant-col-xl-3" }
const _hoisted_4 = { class: "ant-col ant-col-xs-12 ant-col-xl-5" }
const _hoisted_5 = { class: "ant-col ant-col-xs-12 ant-col-xl-3" }
const _hoisted_6 = { class: "ant-col ant-col-xs-24 ant-col-xl-5" }
const _hoisted_7 = { class: "ant-col ant-col-xs-12 ant-col-xl-3" }
const _hoisted_8 = { class: "ant-row" }
const _hoisted_9 = { class: "ant-col ant-col-xs-12 ant-col-xl-4" }
const _hoisted_10 = { class: "ant-col ant-col-xs-12 ant-col-xl-4" }
const _hoisted_11 = { class: "ant-col ant-col-xs-12 ant-col-xl-4" }
const _hoisted_12 = ["disabled"]
const _hoisted_13 = { class: "ant-col ant-col-xs-12 ant-col-xl-4" }
const _hoisted_14 = ["disabled"]
const _hoisted_15 = { class: "ant-col ant-col-xs-12 ant-col-xl-4" }
const _hoisted_16 = ["disabled"]
const _hoisted_17 = { class: "ant-col ant-col-xs-12 ant-col-xl-4" }
const _hoisted_18 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_campo_numerico = _resolveComponent("campo-numerico")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_selecionar_origem_valor_item = _resolveComponent("selecionar-origem-valor-item")!
  const _component_campo_numerico_medida = _resolveComponent("campo-numerico-medida")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_card = _resolveComponent("card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, { layout: "vertical" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_a_form_item, {
                  label: "Frete",
                  class: "ss-margin-campos"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_campo_numerico, {
                      quantidadeCasasDecimais: 2,
                      monetario: true,
                      valor: _ctx.computedItem.valorFrete,
                      "onUpdate:valor": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.computedItem.valorFrete) = $event)),
                      disabled: !_ctx.props.editavel,
                      onBlur: _ctx.calcular
                    }, null, 8, ["valor", "disabled", "onBlur"])
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_a_form_item, {
                  label: "Origem valor",
                  class: "ss-margin-campos"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_selecionar_origem_valor_item, {
                      valor: _ctx.computedItem.origemFrete,
                      "onUpdate:valor": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.computedItem.origemFrete) = $event))
                    }, null, 8, ["valor"])
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_a_form_item, {
                  label: "Seguro",
                  class: "ss-margin-campos"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_campo_numerico, {
                      quantidadeCasasDecimais: 2,
                      monetario: true,
                      valor: _ctx.computedItem.valorSeguro,
                      "onUpdate:valor": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.computedItem.valorSeguro) = $event)),
                      disabled: !_ctx.props.editavel,
                      onBlur: _ctx.calcular
                    }, null, 8, ["valor", "disabled", "onBlur"])
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_a_form_item, {
                  label: "Origem valor",
                  class: "ss-margin-campos"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_selecionar_origem_valor_item, {
                      valor: _ctx.computedItem.origemSeguro,
                      "onUpdate:valor": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.computedItem.origemSeguro) = $event))
                    }, null, 8, ["valor"])
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_a_form_item, {
                  label: "Outras Despesas",
                  class: "ss-margin-campos"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_campo_numerico, {
                      quantidadeCasasDecimais: 2,
                      monetario: true,
                      valor: _ctx.computedItem.outrasDespesas,
                      "onUpdate:valor": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.computedItem.outrasDespesas) = $event)),
                      disabled: !_ctx.props.editavel,
                      onBlur: _ctx.calcular
                    }, null, 8, ["valor", "disabled", "onBlur"])
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_a_form_item, {
                  label: "Origem valor",
                  class: "ss-margin-campos"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_selecionar_origem_valor_item, {
                      valor: _ctx.computedItem.origemOutrasDespesas,
                      "onUpdate:valor": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.computedItem.origemOutrasDespesas) = $event))
                    }, null, 8, ["valor"])
                  ]),
                  _: 1
                })
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_a_form_item, {
                  label: "Peso Bruto",
                  class: "ss-margin-campos"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_campo_numerico_medida, {
                      quantidadeCasasDecimais: 2,
                      peso: true,
                      valor: _ctx.computedItem.pesoBruto,
                      "onUpdate:valor": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.computedItem.pesoBruto) = $event)),
                      disabled: !_ctx.props.editavel
                    }, null, 8, ["valor", "disabled"])
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_a_form_item, {
                  label: "Peso Líquido",
                  class: "ss-margin-campos"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_campo_numerico_medida, {
                      quantidadeCasasDecimais: 2,
                      peso: true,
                      valor: _ctx.computedItem.pesoLiquido,
                      "onUpdate:valor": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.computedItem.pesoLiquido) = $event)),
                      disabled: !_ctx.props.editavel
                    }, null, 8, ["valor", "disabled"])
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_a_form_item, {
                  label: "Nº do Pedido de Compra",
                  class: "ss-margin-campos"
                }, {
                  default: _withCtx(() => [
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      class: "ant-input",
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.computedItem.numeroPedidoCompra) = $event)),
                      disabled: !_ctx.props.editavel
                    }, null, 8, _hoisted_12), [
                      [_vModelText, _ctx.computedItem.numeroPedidoCompra]
                    ])
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_13, [
                _createVNode(_component_a_form_item, {
                  label: "Item do Ped. de Compra",
                  class: "ss-margin-campos"
                }, {
                  default: _withCtx(() => [
                    _withDirectives(_createElementVNode("input", {
                      type: "numeric",
                      class: "ant-input",
                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.computedItem.numeroItemPedidoCompra) = $event)),
                      disabled: !_ctx.props.editavel
                    }, null, 8, _hoisted_14), [
                      [_vModelText, _ctx.computedItem.numeroItemPedidoCompra]
                    ])
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_15, [
                _createVNode(_component_a_form_item, {
                  label: "Cód. do Benefício Fiscal",
                  class: "ss-margin-campos"
                }, {
                  default: _withCtx(() => [
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      class: "ant-input",
                      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.computedItem.impostos.codigoBeneficioFiscal) = $event)),
                      disabled: !_ctx.props.editavel
                    }, null, 8, _hoisted_16), [
                      [_vModelText, _ctx.computedItem.impostos.codigoBeneficioFiscal]
                    ])
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_17, [
                _createVNode(_component_a_form_item, {
                  label: "Número FCI",
                  class: "ss-margin-campos"
                }, {
                  default: _withCtx(() => [
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      class: "ant-input",
                      "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.computedItem.numeroFci) = $event)),
                      disabled: !_ctx.props.editavel
                    }, null, 8, _hoisted_18), [
                      [_vModelText, _ctx.computedItem.numeroFci]
                    ])
                  ]),
                  _: 1
                })
              ])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}