
import {
  computed, defineComponent, reactive,
} from 'vue';
import Card from '@/core/components/Tela/Card.vue';
import CampoNumerico from '@/core/components/Tela/CampoNumerico.vue';
import CampoNumericoMedida from '@/core/components/Tela/CampoNumericoMedida.vue';
import SelecionarOrigemValorItem from '@/components/Compartilhados/SelecionarOrigemValorItem.vue';
import { INotaFiscalItem } from '@/models/Entidades/Fiscal/INotaFiscal';

export default defineComponent({
  name: 'NotaFiscalItemDetalhe',
  props: {
    item: {
      type: Object as () => INotaFiscalItem,
      required: true,
    },
    editavel: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    Card,
    CampoNumerico,
    CampoNumericoMedida,
    SelecionarOrigemValorItem,
  },
  emits: ['update:item', 'calcular'],
  setup(props, { emit }) {
    const state = reactive({});

    const computedItem = computed({
      get: () => props.item,
      set: (val: INotaFiscalItem) => {
        emit('update:item', val);
      },
    });

    function calcular() {
      emit('calcular');
    }

    return {
      props,
      state,
      computedItem,
      calcular,
    };
  },
});
