import ApiERP from '@/core/conectores/ApiERP';
import { IFiltroGenerico } from '@/core/models/BuscaAvancada/IFiltroGenerico';
import { IItemConsultaRapida } from '@/core/models/Consulta/IItemConsultaRapida';
import { IListaPaginada } from '@/core/models/Consulta/IListaPaginada';
import { IParametrosConsulta } from '@/core/models/Consulta/IParametrosConsulta';
import { IParametrosConsultaRapida } from '@/core/models/Consulta/IParametrosConsultaRapida';
import { IPropriedadeConsulta } from '@/core/models/Consulta/PropriedadeConsulta';
import { ETipoArquivo } from '@/core/models/Enumeradores/ETipoArquivo';
import { IRetornoRelatorio } from '@/core/models/IRetornoRelatorio';
import { IRetornoRequisicao } from '@/core/models/IRetornoRequisicao';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IOrdenacaoRegistro } from '@/core/models/IOrdenacaoRegistro';
import storeSistema from '@/store/storeSistema';
import { INotaFiscalDetalhePagamento, INotaFiscalIncidenciaCalculo } from '@/models/Entidades/Fiscal/INotaFiscal';
import { ITituloFinanceiro } from '@/models/Entidades/Financeiro/TitulosFinanceiros/ITituloFinanceiro';
import { IParametrosConsultaDevolucaoVenda } from '@/models/ParametrosRequisicao/Vendas/IParametrosConsultaDevolucaoVenda';
import { IDevolucaoVenda, IDevolucaoVendaItem } from '@/models/Entidades/Vendas/IDevolucaoVenda';
import { EStatusDevolucaoVenda } from '@/models/Enumeradores/Vendas/EStatusDevolucaoVenda';
import { ETipoDevolucaoPagamento } from '@/models/Enumeradores/ETipoDevolucaoPagamento';

/**
 * Serviço de Devoluções de Vendas
 * Fornece todas regras de negócios e lógicas relacionado a Devoluções de Vendas.
 */

class ServicoDevolucaoVenda {
  endPoint = 'devolucoes-vendas';

  apiERP = new ApiERP();

  public requisicaoSistema(): void {
    this.apiERP = new ApiERP(true);
  }

  public async obterPropriedadesConsulta(): Promise<IPropriedadeConsulta[]> {
    const listaPropriedades = await this.apiERP.obterPropriedadesConsulta(this.endPoint);
    return listaPropriedades;
  }

  public async buscaAvancada(parametrosConsulta: IParametrosConsultaDevolucaoVenda, filtros?: IFiltroGenerico[]): Promise<IListaPaginada> {
    let parametrosAdicionais = '';
    if (parametrosConsulta.empresas !== undefined && parametrosConsulta.empresas.length > 0) {
      parametrosConsulta.empresas.forEach((codigoEmpresa) => {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `Empresas=${codigoEmpresa}`;
      });
    }

    if (parametrosConsulta.periodo !== undefined) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `periodo=${parametrosConsulta.periodo}`;

      parametrosAdicionais += `&dataInicial=${parametrosConsulta.dataInicial}`;
      parametrosAdicionais += `&dataFinal=${parametrosConsulta.dataFinal}`;
    }

    if (parametrosConsulta.valorBuscaRapida !== undefined) {
      if (UtilitarioGeral.valorValido(parametrosConsulta.valorBuscaRapida)) {
        parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
        parametrosAdicionais += `valorBuscaRapida=${parametrosConsulta.valorBuscaRapida}`;
      }
    }

    if (filtros !== undefined) {
      const jsonFiltros = JSON.stringify(filtros);
      parametrosAdicionais += `&Filtros=${jsonFiltros}`;
    }
    if (parametrosConsulta.numeroPagina !== undefined && parametrosConsulta.numeroPagina > 0) {
      parametrosAdicionais += `&NumeroPagina=${parametrosConsulta.numeroPagina}`;
    }
    if (parametrosConsulta.qtdeRegistrosPagina !== undefined && parametrosConsulta.qtdeRegistrosPagina > 0) {
      parametrosAdicionais += `&QtdeRegistrosPagina=${parametrosConsulta.qtdeRegistrosPagina}`;
    }
    if (parametrosConsulta.qtdeRegistrosTotal !== undefined && parametrosConsulta.qtdeRegistrosTotal > 0) {
      parametrosAdicionais += `&QtdeRegistrosTotal=${parametrosConsulta.qtdeRegistrosTotal}`;
    }
    if (parametrosConsulta.ordenacao !== undefined) {
      parametrosConsulta.ordenacao.forEach((item) => {
        parametrosAdicionais += `&Ordenacao=${item}`;
      });
    }
    if (parametrosConsulta.codigosSelecionados !== undefined) {
      parametrosConsulta.codigosSelecionados.forEach((codigo) => {
        parametrosAdicionais += `&CodigosSelecionados=${codigo}`;
      });
    }

    const result: any = await this.apiERP.get(`${this.endPoint}${parametrosAdicionais}`);
    const listaPaginada = {} as IListaPaginada;
    listaPaginada.metaData = JSON.parse(result.headers['x-paginacao']);
    listaPaginada.dados = result.data;
    return listaPaginada;
  }

  public async relatorioPadrao(tipoArquivo: ETipoArquivo, parametrosConsulta: IParametrosConsultaDevolucaoVenda, filtros?: IFiltroGenerico[], codigosSelecionados?: Array<number>): Promise<IRetornoRelatorio> {
    const parametrosEmpresas = this.apiERP.obterParametroEmpresas(parametrosConsulta.empresas);
    let parametrosAdicionais = this.apiERP.obterParametrosAdicionaisConsulta(parametrosConsulta, filtros);
    if (parametrosConsulta.periodo !== undefined) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `periodo=${parametrosConsulta.periodo}`;
      parametrosAdicionais += `&dataInicial=${parametrosConsulta.dataInicial}`;
      parametrosAdicionais += `&dataFinal=${parametrosConsulta.dataFinal}`;
    }
    if (codigosSelecionados !== undefined) {
      parametrosAdicionais += this.apiERP.obterParametroCodigosSelecionados(codigosSelecionados);
    }
    const result: any = await this.apiERP.get(`${this.endPoint}/relatorio-padrao/${tipoArquivo}${parametrosEmpresas}${parametrosAdicionais}`);
    return result.data;
  }

  public obterIdentificacaoRegistroGradeCRUD(objeto: IDevolucaoVenda): string {
    return String(objeto.codigo);
  }

  public async consultaRapida(parametrosConsultaRapida: IParametrosConsultaRapida): Promise<IItemConsultaRapida[]> {
    const parametrosAdicionais = this.apiERP.obterParametrosAdicionaisConsultaRapida(parametrosConsultaRapida);
    const result: any = await this.apiERP.get(`${this.endPoint}/consulta-rapida${parametrosAdicionais}`);
    return result.data;
  }

  public async obterDevolucaoVendaConsultaRapida(codigoDevolucao: number, empresas?: number[]): Promise<IItemConsultaRapida> {
    const parametrosConsultaRapida: IParametrosConsultaRapida = {
      valor: codigoDevolucao.toString(), filtrarPorCodigo: true, recursoAssociado: '',
    };

    if (UtilitarioGeral.validaLista(empresas)) {
      parametrosConsultaRapida.empresas = empresas;
    } else {
      parametrosConsultaRapida.empresas = storeSistema.getters.empresasUsuarioAutenticado();
    }

    const listaDevolucoes = await this.consultaRapida(parametrosConsultaRapida);
    if (UtilitarioGeral.validaLista(listaDevolucoes)) {
      return listaDevolucoes[0];
    }

    return {} as IItemConsultaRapida;
  }

  public async incluir(devolucao: IDevolucaoVenda): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.post(`${this.endPoint}`, devolucao);
    return result.data;
  }

  public async incluirCabecalho(devolucao: IDevolucaoVenda): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.post(`${this.endPoint}/cabecalho`, devolucao);
    return result.data;
  }

  public async incluirItens(devolucao: IDevolucaoVenda): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.post(`${this.endPoint}/itens`, devolucao);
    return result.data;
  }

  public async alterar(devolucao: IDevolucaoVenda): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.put(`${this.endPoint}/${devolucao.codigo}`, devolucao);
    return result.data;
  }

  public async atualizarCampoCabecalho(devolucao: IDevolucaoVenda, campo: string): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.put(`${this.endPoint}/campo/${campo}`, devolucao);
    return result.data;
  }

  public async atualizarItem(item: IDevolucaoVendaItem, valorTotal: number, empresa: number): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.put(`${this.endPoint}/item/valor-total/${valorTotal}/empresa/${empresa}`, item);
    return result.data;
  }

  public async atualizarTipoDevolucaoPagamento(codigo: number, empresa: number, tipo: ETipoDevolucaoPagamento): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.put(`${this.endPoint}/tipo-devolucao-pagamento/${codigo}/empresa/${empresa}/tipo/${tipo}`);
    return result.data;
  }

  public async atualizarCredito(codigo: number, empresa: number, detalhePagamento: INotaFiscalDetalhePagamento): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.put(`${this.endPoint}/credito/${codigo}/empresa/${empresa}`, detalhePagamento);
    return result.data;
  }

  public async atualizarOrdenacao(ordenacaoRegistros: IOrdenacaoRegistro[], empresa: number): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.put(`${this.endPoint}/ordenacao/empresa/${empresa}`, ordenacaoRegistros);
    return result.data;
  }

  public async alterarEtapa(codigo: number, empresa: number, statusNovo: EStatusDevolucaoVenda): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.put(`${this.endPoint}/etapa/${codigo}/empresa/${empresa}/status-novo/${statusNovo}`);
    return result.data;
  }

  public async alterarEtapaVarios(empresa: number, proximaEtapa: boolean, cancelamento: boolean, devolucoes: number[]): Promise<IRetornoRequisicao> {
    let parametrosAdicionais = '';
    for (let contador = 0; contador < devolucoes.length; contador += 1) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `Devolucoes=${devolucoes[contador]}`;
    }

    const result: any = await this.apiERP.put(`${this.endPoint}/etapa-varios/empresa/${empresa}/proxima-etapa/${proximaEtapa}/cancelamento/${cancelamento}${parametrosAdicionais}`);
    return result.data;
  }

  public async excluir(codigo: number, empresa: number): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.delete(`${this.endPoint}/${codigo}/empresa/${empresa}`);
    return result.data;
  }

  public async excluirVarios(empresa: number, devolucoes: number[]): Promise<IRetornoRequisicao> {
    let parametrosAdicionais = '';
    for (let contador = 0; contador < devolucoes.length; contador += 1) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `Devolucoes=${devolucoes[contador]}`;
    }

    const result: any = await this.apiERP.delete(`${this.endPoint}/varios/empresa/${empresa}${parametrosAdicionais}`);
    return result.data;
  }

  public async excluirItem(codigo: number, empresa: number, valorTotal: number): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.delete(`${this.endPoint}/item/${codigo}/empresa/${empresa}/valor-total/${valorTotal}`);
    return result.data;
  }

  public async obter(codigo: number, empresa: number): Promise<IDevolucaoVenda> {
    const result: any = await this.apiERP.get(`${this.endPoint}/${codigo}/empresa/${empresa}`);
    return result.data;
  }

  public async cancelar(codigo: number, empresa: number): Promise<IRetornoRequisicao> {
    const result: any = await this.apiERP.put(`${this.endPoint}/cancelar/${codigo}/empresa/${empresa}`);
    return result.data;
  }

  public async obterItens(devolucao: number, notaFiscal: number, empresa: number): Promise<IDevolucaoVendaItem[]> {
    const result: any = await this.apiERP.get(`${this.endPoint}/itens/${devolucao}/nota-fiscal/${notaFiscal}/empresa/${empresa}`);
    return result.data;
  }

  public async obterTitulosFinanceirosItens(empresa: number, codigosItens: number[]): Promise<ITituloFinanceiro[]> {
    let parametrosAdicionais = '';
    for (let contador = 0; contador < codigosItens.length; contador += 1) {
      parametrosAdicionais += (parametrosAdicionais !== '' ? '&' : '?');
      parametrosAdicionais += `CodigosItens=${codigosItens[contador]}`;
    }

    const result: any = await this.apiERP.get(`${this.endPoint}/titulos-financeiros/empresa/${empresa}parametrosAdicionais`);
    return result.data;
  }

  public async imprimirDevolucao(codigo: number, empresa: number, tipoArquivo: ETipoArquivo): Promise<IRetornoRelatorio> {
    const result: any = await this.apiERP.get(`${this.endPoint}/impressao/${codigo}/empresa/${empresa}/tipo-arquivo/${tipoArquivo}`);
    return result.data;
  }

  public obterIncidenciasNotaFiscalPadrao(): INotaFiscalIncidenciaCalculo[] {
    const incidenciasCalculo: INotaFiscalIncidenciaCalculo[] = [
      {
        codigo: 0, codigoNotaFiscal: 0, incidencia: 'ICMS', identificador: 'Frete', valor: true,
      },
      {
        codigo: 0, codigoNotaFiscal: 0, incidencia: 'ICMS', identificador: 'OutrasDespesas', valor: true,
      },
      {
        codigo: 0, codigoNotaFiscal: 0, incidencia: 'ICMS', identificador: 'Seguro', valor: false,
      },
      {
        codigo: 0, codigoNotaFiscal: 0, incidencia: 'ICMS', identificador: 'IPI', valor: false,
      },
      {
        codigo: 0, codigoNotaFiscal: 0, incidencia: 'ICMS', identificador: 'Desconto', valor: true,
      },
      {
        codigo: 0, codigoNotaFiscal: 0, incidencia: 'IPI', identificador: 'Frete', valor: true,
      },
      {
        codigo: 0, codigoNotaFiscal: 0, incidencia: 'IPI', identificador: 'OutrasDespesas', valor: true,
      },
      {
        codigo: 0, codigoNotaFiscal: 0, incidencia: 'IPI', identificador: 'Seguro', valor: false,
      },
      {
        codigo: 0, codigoNotaFiscal: 0, incidencia: 'IPI', identificador: 'Desconto', valor: true,
      },
      {
        codigo: 0, codigoNotaFiscal: 0, incidencia: 'PIS', identificador: 'Frete', valor: true,
      },
      {
        codigo: 0, codigoNotaFiscal: 0, incidencia: 'PIS', identificador: 'OutrasDespesas', valor: true,
      },
      {
        codigo: 0, codigoNotaFiscal: 0, incidencia: 'PIS', identificador: 'Seguro', valor: false,
      },
      {
        codigo: 0, codigoNotaFiscal: 0, incidencia: 'PIS', identificador: 'Desconto', valor: true,
      },
      {
        codigo: 0, codigoNotaFiscal: 0, incidencia: 'COFINS', identificador: 'Frete', valor: true,
      },
      {
        codigo: 0, codigoNotaFiscal: 0, incidencia: 'COFINS', identificador: 'OutrasDespesas', valor: true,
      },
      {
        codigo: 0, codigoNotaFiscal: 0, incidencia: 'COFINS', identificador: 'Seguro', valor: false,
      },
      {
        codigo: 0, codigoNotaFiscal: 0, incidencia: 'COFINS', identificador: 'Desconto', valor: true,
      },
      {
        codigo: 0, codigoNotaFiscal: 0, incidencia: 'TotalNota', identificador: 'Frete', valor: true,
      },
      {
        codigo: 0, codigoNotaFiscal: 0, incidencia: 'TotalNota', identificador: 'OutrasDespesas', valor: true,
      },
      {
        codigo: 0, codigoNotaFiscal: 0, incidencia: 'TotalNota', identificador: 'Seguro', valor: true,
      },
      {
        codigo: 0, codigoNotaFiscal: 0, incidencia: 'TotalNota', identificador: 'IPI', valor: true,
      },
      {
        codigo: 0, codigoNotaFiscal: 0, incidencia: 'TotalNota', identificador: 'Desconto', valor: true,
      },
    ];

    return incidenciasCalculo;
  }
}
export default ServicoDevolucaoVenda;
